import Config from './config/frontConfig'
import router from './router'

const axios = require(`axios`)

const cookieStore = require(`./store/cookie_store`)
const frontConfig = new Config()
const instance = axios.create({
    baseURL: frontConfig.getApiURL(),
    timeout: 5000,
    headers: {
        'Content-Type': `application/json`,
        "Access-Control-Allow-Origin": `*`,
        "Access-Control-Allow-Methods": `OPTIONS`
    }
})

instance.interceptors.request.use((config) => {
    // Do something before request is sent
    const accessToken = cookieStore.getCookie(`access_token`)
    if (accessToken) {
        config.headers.Authorization = accessToken
    }
    return config
}, (error) => {
    // Do something with request error
    return Promise.reject(error)
})

// Add a response interceptor
instance.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
}, async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const handledErrors = {
        404: `error_not_found`
    }
    if (handledErrors[error.response.status]) {
        // Redirect to error page
        await router.replace({ name: handledErrors[error.response.status] })
    }
    return Promise.reject(error)
})

export const myAxios = instance
