import Vue from 'vue'
import VueCookies from 'vue-cookie'
import store from './store/store'
import router from './router'
import App from './components/app.vue'
import Config from './config/frontConfig'
import VueMeta from 'vue-meta'

import { translator as Translator } from "translator"

Vue.use(VueCookies)

Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})
Vue.prototype.$config = new Config()
Vue.prototype.$session = {
    user: null
}
Vue.prototype.getUsername = function () {
    return this.$cookie.get(`username`)
}

/**
 *
 * @param value
 * @param {{}} params
 * @returns {*}
 */
Translator.qTranslate = function (value, params) {
    return Translator.translate(document.documentElement.lang, value, params)
}

// translate filter
Vue.filter(`trans`, function (value, params) {
    return Translator.qTranslate(value, params)
})
Vue.prototype.$translator = Translator.qTranslate

new Vue({

    metaInfo: {
        titleTemplate: `Murloc Village | %s`,
        htmlAttrs: {
            lang: `fr`
            // amp: true
        }
    },
    el: `#app`,
    router,
    store,
    data: {
        characters: []
    },
    render: h => h(App)
}).$mount(`#app`)
