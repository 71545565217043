const svgImage = {}
svgImage.arrow_up = [`M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z`]
svgImage.arrow_down = [`M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z`]
svgImage.lock_empty = [`M11.5 8h-7a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1zm-7-1a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-7zm0-3a3.5 3.5 0 1 1 7 0v3h-1V4a2.5 2.5 0 0 0-5 0v3h-1V4z`]
svgImage.unlock_empty = [`M9.655 8H2.333c-.264 0-.398.068-.471.121a.73.73 0 0 0-.224.296 1.626 1.626 0 0 0-.138.59V14c0 .342.076.531.14.635.064.106.151.18.256.237a1.122 1.122 0 0 0 .436.127l.013.001h7.322c.264 0 .398-.068.471-.121a.73.73 0 0 0 .224-.296 1.627 1.627 0 0 0 .138-.59V9c0-.342-.076-.531-.14-.635a.658.658 0 0 0-.255-.237A1.122 1.122 0 0 0 9.655 8zm.012-1H2.333C.5 7 .5 9 .5 9v5c0 2 1.833 2 1.833 2h7.334c1.833 0 1.833-2 1.833-2V9c0-2-1.833-2-1.833-2zM8.5 4a3.5 3.5 0 1 1 7 0v3h-1V4a2.5 2.5 0 0 0-5 0v3h-1V4z`]
svgImage.trash = [
    `M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z`,
    `M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z`]
svgImage.x_circle = [`M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z`]
svgImage.replay = [`M9.502 5.013a.144.144 0 0 0-.202.134V6.3a.5.5 0 0 1-.5.5c-.667 0-2.013.005-3.3.822-.984.624-1.99 1.76-2.595 3.876C3.925 10.515 5.09 9.982 6.11 9.7a8.741 8.741 0 0 1 1.921-.306 7.403 7.403 0 0 1 .798.008h.013l.005.001h.001L8.8 9.9l.05-.498a.5.5 0 0 1 .45.498v1.153c0 .108.11.176.202.134l3.984-2.933a.494.494 0 0 1 .042-.028.147.147 0 0 0 0-.252.494.494 0 0 1-.042-.028L9.502 5.013zM8.3 10.386a7.745 7.745 0 0 0-1.923.277c-1.326.368-2.896 1.201-3.94 3.08a.5.5 0 0 1-.933-.305c.464-3.71 1.886-5.662 3.46-6.66 1.245-.79 2.527-.942 3.336-.971v-.66a1.144 1.144 0 0 1 1.767-.96l3.994 2.94a1.147 1.147 0 0 1 0 1.946l-3.994 2.94a1.144 1.144 0 0 1-1.767-.96v-.667z`]
svgImage.bell = [
    `M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z`
]
svgImage.power = [
    `M7.5 1v7h1V1h-1z`,
    `M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z`
]
svgImage.murloc = [
    `M2168 2891 c-57 -51 -39 -125 39 -157 35 -15 53 -40 53 -73 0 -25`,
    `-21 -27 -117 -10 -103 18 -338 -15 -436 -61 -25 -12 -87 -57 -138 -101 -120`,
    `-103 -251 -192 -353 -240 -288 -134 -667 -157 -946 -58 -129 46 -130 46 -60`,
    `-11 116 -93 230 -145 451 -206 129 -35 141 -37 334 -41 288 -7 472 25 582 100`,
    `26 19 91 64 145 100 189 131 287 164 503 170 117 3 144 -14 174 -106 15 -47`,
    `168 -566 197 -668 3 -11 -10 -28 -36 -49 -48 -38 -57 -67 -36 -108 9 -17 13`,
    `-37 10 -44 -6 -15 -94 -85 -117 -94 -11 -4 -39 19 -86 69 -83 86 -148 125`,
    `-276 161 -49 14 -95 33 -102 42 -7 10 -29 68 -50 130 -20 61 -42 119 -49 128`,
    `-12 17 -265 138 -273 131 -2 -3 12 -22 31 -43 19 -21 49 -60 67 -86 40 -58`,
    `133 -268 123 -278 -8 -8 -123 -38 -146 -38 -9 0 -28 25 -47 63 -18 34 -52 90`,
    `-76 124 -53 74 -63 78 -238 88 -66 3 -130 9 -143 12 -39 9 -24 -12 26 -36 85`,
    `-41 206 -119 240 -154 18 -19 48 -65 67 -103 l34 -69 -21 -22 c-26 -28 -28`,
    `-27 -168 39 -99 47 -109 49 -170 45 -80 -5 -334 -58 -323 -67 4 -3 85 -14 180`,
    `-23 179 -17 237 -33 320 -85 49 -31 52 -46 23 -103 -29 -56 -52 -64 -127 -45`,
    `-114 30 -145 24 -268 -49 -113 -68 -146 -92 -110 -81 78 22 224 48 295 53 110`,
    `6 164 -5 168 -35 5 -36 -12 -49 -114 -82 -52 -18 -100 -38 -108 -45 -22 -22`,
    `-54 -344 -38 -386 8 -20 21 -28 62 -37 71 -16 98 -15 150 9 52 23 60 33 60 74`,
    `0 38 -7 48 -47 69 l-33 17 0 99 c0 95 1 100 25 116 30 20 55 12 55 -17 0 -37`,
    `60 -172 100 -226 22 -28 57 -64 79 -80 23 -15 48 -43 57 -61 17 -36 12 -82 -9`,
    `-82 -6 0 -69 11 -139 25 -189 37 -201 28 -225 -158 -9 -69 -13 -133 -9 -142 5`,
    `-15 17 -17 67 -13 59 4 64 7 110 56 27 29 52 52 56 53 5 0 59 4 121 8 77 5`,
    `117 12 126 22 8 7 22 63 31 124 8 60 21 116 29 123 7 7 40 26 73 42 48 22 64`,
    `26 76 17 8 -7 47 -56 86 -109 39 -54 83 -105 97 -115 26 -16 28 -16 80 11 54`,
    `29 222 132 237 145 10 10 -20 68 -38 75 -8 3 -15 12 -15 21 0 21 -9 19 -86`,
    `-16 -79 -37 -79 -37 -189 51 -69 54 -81 60 -123 60 -26 0 -69 -7 -96 -15 -81`,
    `-25 -92 -22 -110 32 -33 95 -21 103 100 61 64 -22 114 -32 179 -36 111 -6 184`,
    `9 275 54 123 62 190 162 190 285 l0 47 99 67 c81 54 103 65 118 57 12 -7 31`,
    `-54 58 -144 34 -113 43 -133 60 -133 31 0 29 30 -10 155 -20 63 -34 124 -31`,
    `135 3 11 25 32 50 47 32 19 46 35 51 57 15 69 -82 191 -151 191 -38 0 -42 5`,
    `-59 68 -7 26 -49 171 -93 322 -204 697 -242 830 -242 841 0 6 9 22 20 36 29`,
    `37 26 79 -9 114 -42 41 -107 41 -153 0z m-69 -1698 c14 -11 33 -38 41 -61 13`,
    `-37 12 -45 -3 -76 -22 -48 -61 -71 -117 -71 -57 0 -97 25 -117 73 -34 80 36`,
    `169 126 159 24 -3 55 -14 70 -24z`
]

svgImage.discord = [`M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z`]

module.exports = svgImage
