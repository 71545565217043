/* jshint esversion: 9 */
/* jshint expr: true */

"use strict"
import { myAxios } from '@/custom_axios'

export class VotesApi {
    /**
     * Return all the votes
     * @returns {Promise<*>}
     */
    static async getVotes () {
        return myAxios.get(`/api/votes`)
    }
}
