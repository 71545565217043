<template>
  <div class="col-lg-12">
    <h3>Emailing</h3>
    <table
      v-if="emails"
      class="table table-dark center_table"
    >
      <thead>
        <tr>
          <th>Email ID</th>
          <th>Subject</th>
          <th>Recipient Type</th>
          <th>Number Target</th>
          <th>Number Send</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tr
        v-for="email in emails"
        :key="email.id"
      >
        <td>{{ email.id }}</td>
        <td>{{ email.subject }}</td>
        <td>
          <span v-if="email.canBeSendTo === 6">
            Game Masters
          </span>
        </td>
        <td>{{ email.nbrToSend }}</td>
        <td>{{ email.nbrSent }}</td>
        <td>
          <button
            class="btn btn-primary"
            @click="sendTest(email.id)"
          >
            Test
          </button>
          <button
            v-if="canSendMail(email)"
            class="btn btn-primary"
            @click="sendMail(email.id)"
          >
            Send
          </button>
        </td>
      </tr>
      <tbody />
    </table>
  </div>
</template>

<script>
'use strict'
import { Common } from "@/common"
import { MailApi } from "@/api/MailApi"

// const ONLY_USER = 1
// const ALL = 2
// const ALL_LVL_MAX = 3
// const ALL_INACTIVE = 4
// const ALL_INACTIVE_LVL_MAX = 5
const ALL_GMS = 6

export default {

    data () {
        return {
            emails: undefined
        }
    },

    metaInfo () {
        return Common.getMetaInfo(this.$route.name)
    },
    mounted () {
        this.loadEmails()
    },
    methods: {
        loadEmails () {
            MailApi.getEmails().then((response) => {
                this.emails = response.data
            })
        },
        sendTest (emailId) {
            MailApi.sendTest(emailId).then((response) => {
                this.loadEmails()
            })
        },
        sendMail (emailId) {
            MailApi.sendEmails(emailId).then((response) => {
                this.loadEmails()
            })
        },
        canSendMail (emailId) {
            return emailId?.canBeSendTo === ALL_GMS && !emailId.alreadyStarted
        }
    }
}

</script>
