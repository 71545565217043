/* jshint esversion: 6 */
/* jshint expr: true */
/**
 * Created by clem on 4/9/17.
 */
'use strict'

import Services from "character-services"
/**
 * @typedef {Object} VoteSite
 * @property {number|undefined} interval - The ID of the interval used to update the vote timer.
 * @property {number|undefined} timer - The remaining time in seconds until the vote expires.
 * @property {string} updated_at - The timestamp of the last time the vote was updated.
 * @property {Object} vote - An object representing the vote.
 * @property {number} vote.id - The id of the vote site.
 * @property {number} vote.seconds - The remaining time in seconds until the vote expires.
 * @property {string} vote.name - The remaining time in seconds until the vote expires.
 * @property {string} vote.http_link - The remaining time in seconds until the vote expires.
 */

/**
 * An object representing a web account with an ID, amount of money, and associated vote sites.
 * @typedef {Object} Account
 * @property {number} id - The unique identifier of the account.
 * @property {number} money - The amount of money in the account.
 * @property {VoteSite[]} account_votes - An array of vote sites associated with the account.
 */

export default {
    /**
     *
     * @param state
     * @return {Account|null}
     */
    getAccount (state) {
        return state.account
    },

    getServiceAccount (state) {
        if (state.account) {
            return state.account
        } else {
            return Services.ncAccount()
        }
    },

    getServiceCharacter (state) {
        if (state.currentCharacter) {
            return state.currentCharacter
        } else {
            return Services.ncCharacter()
        }
    },

    alertsMessages (state) {
        return state.alerts
    },

    getUser (state) {
        return state.user
    },

    getCurrentCharacter (state) {
        return state.currentCharacter
    },

    getCharacters (state) {
        return state.characters
    },

    getViewerScriptsLoaded (state) {
        return state.viewerScriptsLoaded
    }
}
