/**
 * Created by clem on 26/07/17.
 */
"use strict"

function CookieStore () {
    /**
     *
     * @param cname
     * @param cvalue
     * @param exdays
     */
    this.setCookie = function (cname, cvalue, exdays) {
        const d = new Date()
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
        const expires = `expires=` + d.toUTCString()
        document.cookie = cname + `=` + cvalue + `;` + expires + `;path=/; secure`
    }

    this.delCookie = function (name) {
        do {
            document.cookie = name + `=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
        } while (this.getCookie(name) !== null)
    }

    /**
     * Get cookie by name
     * source: https://www.w3schools.com/js/js_cookies.asp
     *
     * @param cname
     * @returns {*}
     */
    this.getCookie = function (cname) {
        console.log(`getCookie`)
        const name = cname + `=`
        const decodedCookie = decodeURIComponent(document.cookie)
        const ca = decodedCookie.split(`;`)
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) === ` `) {
                c = c.substring(1)
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length)
            }
        }
        return null
    }
}

module.exports = new CookieStore()
