"use strict"

const returnImageHeight = (imageElement, parentElement) => {
    if (imageElement && parentElement) {
        const intRef = setInterval(() => {
            if (imageElement.naturalWidth >= parentElement.clientWidth) {
                const paddingTotal = parseInt(window.getComputedStyle(parentElement).paddingLeft) + parseInt(window.getComputedStyle(parentElement).paddingRight)
                const proportion = (parentElement.clientWidth - (paddingTotal)) / imageElement.naturalWidth
                const imgHeight = imageElement.naturalHeight * proportion
                const imgWidth = imageElement.naturalWidth * proportion
                imageElement.setAttribute(`height`, `${imgHeight}`)
                imageElement.setAttribute(`width`, `${imgWidth}`)
            }
            if (imageElement && imageElement.clientWidth > 0) {
                clearInterval(intRef)
            }
        }, 10)
    }
}

module.exports = {
    returnImageHeight
}
