<template>
  <div class="col-12">
    <div class="row justify-content-center">
      <div class="col-8">
        <div class="row">
          <form
            id="forum_post"
            method="post"
            style="width: 100%"
            @submit.prevent="onSubmit"
          >
            <div
              v-if="getUsername()"
              class="forum_form row"
            >
              <div class="col-3">
                &nbsp;
              </div>
              <div
                v-if="getUsername()"
                class="col-9"
              >
                <label
                  class="text-lg"
                  for="post_title"
                >{{ 'forum_message_title_label' | trans }}</label>
                <label
                  v-if="errors.post_title"
                  class="form-text text-danger"
                >{{ errors.post_title | trans }}</label>
                <input
                  id="post_title"
                  v-model="title"
                  maxlength="64"
                  type="text"
                  required
                  name="post_title"
                  class="form-control form-text"
                >
                <post-text-area :errors="errors" />
              </div>
              <div class="col-3">
                &nbsp;
              </div>
              <div
                v-if="getUsername()"
                class="col-9"
              >
                <button
                  v-if="getUsername()"
                  class="float-right btn warcraft_btn"
                >
                  {{ 'forum_post_submit' | trans }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

"use strict"
import { TopicsApi } from "@/api/TopicsApi"
import PostTextArea from "@/components/modules/forum/PostTextArea.vue"
import Config from "@/config/frontConfig"
import { formDataToJson } from "@/libs/formDataUtils"

export default {
    name: `NewTopic`,
    components: { PostTextArea },
    data () {
        return {
            errors: {},
            title: ``,
            config: Config
        }
    },
    mounted () {
        console.log(`YOLO`)
    },

    methods: {

        async onSubmit () {
            const form = document.querySelector(`#forum_post`)
            const formData = new FormData(form)

            const postData = formDataToJson(formData)
            try {
                const topic = await TopicsApi.postTopic(postData)
                await this.$router.push({ name: `forum_topic`, params: { topic_slug: topic.data.slug, id: topic.data.id } })
                this.errors = {}
            } catch (err) {
                this.errors = err.response.data
            }
        }
    }
}
</script>
