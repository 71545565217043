<template>
  <div
    id="app"
    class="container-fluid"
    :class="appClass"
  >
    <div
      v-if="routeIsAuth"
    >
      <transition>
        <app-alerts />
      </transition>
      <transition>
        <router-view :id="$route.name" />
      </transition>
    </div>

    <div v-else-if="routeIsError">
      <transition>
        <router-view :id="$route.name" />
      </transition>
      <transition>
        <app-footer />
      </transition>
    </div>

    <div v-else>
      <user-information-bar />
      <transition>
        <app-header @switchlang="switchLang" />
      </transition>
      <transition>
        <app-alerts />
      </transition>
      <transition>
        <router-view :id="$route.name" />
      </transition>
      <transition>
        <app-footer />
      </transition>
    </div>
  </div>
</template>

<script>

import AppFooter from "@/components/main/AppFooter.vue"
import AppHeader from "@/components/main/AppHeader.vue"
import AppAlerts from "@/components/main/AppAlerts.vue"
import UserInformationBar from "@/components/main/UserInformationBar.vue"

export default {
    name: `App`,
    components: { UserInformationBar, AppFooter, AppHeader, AppAlerts },
    data () {
        return {}
    },
    computed: {
        routeIsAuth () {
            return this.$route.name.indexOf(`auth_`) !== -1
        },
        routeIsError () {
            return this.$route.name.indexOf(`error_`) !== -1
        },
        appClass () {
            if (this.routeIsAuth) {
                return `auth_class`
            } else if (this.routeIsError) {
                return `error_class`
            }
            return ``
        }
    },
    errorCaptured (err) {
        console.error(`errorCaptured`, err)
        return false
    },
    methods: {

        /**
     * Switch lang
     * @param newLang : 2 chars to set the language like 'en'
     */
        switchLang: function (newLang) {
            this.$cookie.set(`userLang`, newLang)
            this.updateAll(this.$children)
        },

        /**
     * Upate all the child components
     * @param children
     */
        updateAll: function (children) {
            if (!Array.isArray(children)) {
                throw new Error(`Children is not an array`)
            }

            const current = this
            children.forEach(function (element) {
                if (Array.isArray(element.$children)) {
                    current.updateAll(element.$children)
                }
                element.$forceUpdate()
            })
        }
    }
}
</script>
