<template>
  <div class="col-lg-10 warcraft_article p-3 mb-4">
    <div class="row">
      <div
        v-if="character.level < serviceConf.maxLevel"
        class="col-md-8 col-sm-12"
      >
        <h3>{{ `title_level` | trans }}</h3>
        <p>
          {{ `text_level_one` | trans({':level': character.level + 1}) }}
          <wow-money :money="nextLvlMoney" />
          {{ `text_level_two` | trans }}
          <character-name :character="character" />
          .<br>
          {{ `text_level_three` | trans({':price': (character.level + 1) * 2}) }}
          <img
            src="/images/wine.webp"
            alt="wine"
          >
        </p>
        <ul>
          <li>{{ `description_level_1` | trans }}</li>
          <li>{{ `description_level_2` | trans }}</li>
        </ul>
        <nc-link :no-button="true">
          <form
            method="post"
            @submit.prevent="onSubmit"
          >
            <button
              :disabled="isSubmitted"
              class="btn warcraft_btn"
              type="submit"
            >
              {{ 'btn_level'|trans }}
            </button>
          </form>
        </nc-link>
      </div>
      <div
        v-else
        class="col-md-8 col-sm-12"
      >
        <h3>{{ `title_level` | trans }}</h3>
        <p>
          <character-name :character="character" />
          {{ `text_level_max` | trans({':level': character.level, ':max_level': serviceConf.maxLevel}) }}
        </p>
      </div>

      <div class="col-md-2 offset-md-1">
        <img
          class="img-fluid"
          src="/images/level.webp"
          alt="Level"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ServicesApi } from "@/api/ServicesApi"
import WowMoney from '../../ui/WowMoney.vue'
import NcLink from "../../ui/NcLink.vue"
import CharacterName from "@/components/ui/CharacterName"

import { nextLevelToMoney, serviceConf } from 'character-services'

export default {
    components: { CharacterName, NcLink, WowMoney },

    data () {
        return {
            isSubmitted: false,
            serviceConf
        }
    },
    computed: {
        selectedCharacter () {
            const ret = this.$root.characters.find(element => element.selected)
            return ret || {}
        },

        character () {
            return this.$store.getters.getServiceCharacter
        },

        nextLvlMoney () {
            return nextLevelToMoney(this.character.level + 1)
        }
    },

    methods: {
        async onSubmit () {
            try {
                this.isSubmitted = true
                await this.$store.dispatch(`addAlert`, { key: `info_level`, type: `info` })
                await ServicesApi.putLevel()
                // Remove fail message if exists
                await this.$store.dispatch(`addAlert`, { key: `success_level`, type: `success` })
                await this.$store.dispatch(`loadCharacters`)
                await this.$store.dispatch(`loadAccount`)
            } catch (error) {
                // Connection fail message
                let errorMessage = `error_level`

                if (error.response.status === 500) {
                    errorMessage = `error_unexpected`
                } else if (error.response.status === 402) {
                    errorMessage = `error_money`
                } else if (error.response.status === 400) {
                    errorMessage = `error_level`
                }

                await this.$store.dispatch(`addAlert`, {
                    key: errorMessage,
                    type: `danger`
                })
            } finally {
                this.isSubmitted = false
                await this.$store.dispatch(`delAlert`, `info_level`)
            }
            return false
        }
    }
}
</script>
