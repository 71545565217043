<template>
  <div>
    <p>
      {{ 'home_description_one' | trans }}
      <strong>{{ 'home_description_two' | trans }}</strong>
      {{ 'home_description_three' | trans }}
      <strong>{{ 'home_description_four' | trans }}</strong>
      {{ 'home_description_five' | trans }}
    </p>
    <p v-if="worldEvents.loveIsInTheAir">
      <strong>{{ 'love_is_in_the_air_title' | trans }}</strong>
      {{ 'love_is_in_the_air_description' | trans }}
    </p>
    <p v-if="topic?.posts">
      <router-link
        :to="{ name: 'forum_topic', params: { topic_slug: topic.slug, id: topic.id }}"
        class="textual_link"
        exact
      >
        <strong>{{ topic.title }}</strong><br>
        <span>{{ topicStart }}...</span>
      </router-link>
    </p>
  </div>
</template>

<script>
import { worldEvents } from "@/worldEvents"
import { TopicsApi } from "@/api/TopicsApi"

export default {
    name: `MainPanelTexte`,
    data () {
        return {
            worldEvents,
            topic: null
        }
    },
    computed: {
        topicStart () {
            if (!this?.topic?.posts) return ``
            return this.topic.posts[0].text.split(` `).slice(0, 20).join(` `)
        }
    },
    mounted () {
        this.getLastTopic()
    },

    methods: {
        async getLastTopic () {
            const response = await TopicsApi.geLastPinnedTopic()
            this.topic = response.data
        }
    }
}
</script>

<style scoped>

</style>
