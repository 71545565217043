<template>
  <div>
    <div class="row justify-content-md-center">
      <div
        :id="$route.name+'_form'"
        class="col-lg-2 centered warcraft_form auth_form"
      >
        <form
          id="form_login_forgotten"
          @submit.prevent="onSubmit"
        >
          <div class="form-group">
            <label
              v-if="errors.account"
              for="account"
              class="form-text text-danger"
            >* {{ errors.account | trans }}</label>
            <input
              id="account"
              :disabled="isSubmitted"
              :placeholder="'lbl_email' | trans"
              maxlength="255"
              type="text"
              name="account"
              class="form-control"
              aria-describedby="register_email_help"
            >
            <app-captcha
              :errors="errors"
              :is-submitted="isSubmitted"
            />
          </div>
          <button
            id="btn_login"
            :disabled="isSubmitted"
            class="btn warcraft_btn"
            name="doLogin"
            type="submit"
            value="Check"
          >
            {{ 'btn_submit' | trans }}
          </button>
        </form>
        <br>
      </div>
    </div>
  </div>
</template>
<script>
import appCaptcha from './Captcha.vue'
import { AccountApi } from "@/api/AccountApi"
import { formDataToJson } from "@/libs/formDataUtils"

export default {
    components: { appCaptcha },
    data () {
        return {
            errors: {},
            isSubmitted: false
        }
    },

    methods: {

        async onSubmit () {
            const form = document.querySelector(`#form_login_forgotten`)
            const formData = new FormData(form)
            const postData = formDataToJson(formData)
            try {
                this.isSubmitted = false
                await AccountApi.postAccountInfoLost(postData)
                await this.$router.push({ name: `main_home` })
                await this.$store.dispatch(`addAlert`, {
                    key: `msg_init_password`,
                    type: `success`
                })
            } catch (error) {
                this.errors = error.response.data
            } finally {
                this.isSubmitted = false
            }
        }

    }
}

</script>
