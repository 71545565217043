<template><span>&nbsp;</span></template>
<script>
export default {
    data () {
        return {}
    },
    mounted () {
        this.onLoad()
    },
    methods: {
        async onLoad () {
            await this.$router.push({ name: `main_home` })
            await this.$store.dispatch(`addAlert`, {
                key: `validate_account_done`,
                type: `success`
            })
        }
    }
}
</script>
