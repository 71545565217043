/* jshint esversion: 11 */
"use strict"

class DateTime {
    constructor (inputTime) {
        this.inputTime = new Date(inputTime)
    }

    returnDiff () {
        if (this.diffHour() <= 24) {
            return {
                time: Math.round(this.diffHour()) + `h ` + Math.round(this.diffMin() % 60) + `min`,
                dictionary_key: `time_hour`
            }
        } else if (this.diffDays() <= 30) {
            return {
                time: Math.round(this.diffDays()),
                dictionary_key: `time_days`
            }
        }

        return {
            time: new Date(this.inputTime).toISOString().slice(0, 10),
            dictionary_key: `time_other`
        }
    }

    diffDays () {
        return this.diffHour() / 24
    }

    diffHour () {
        return this.diffMin() / 60
    }

    diffMin () {
        return ((new Date()) - this.inputTime) / (60 * 1000)
    }

    /* For a given date, get the ISO week number
     *
     * Based on information at:
     *
     *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
     *
     * Algorithm is to find nearest thursday, it's year
     * is the year of the week number. Then get weeks
     * between that date and the first day of that year.
     *
     * Note that dates in one year can be weeks of previous
     * or next year, overlap is up to 3 days.
     *
     * e.g. 2014/12/29 is Monday in week  1 of 2015
     *      2012/1/1   is Sunday in week 52 of 2011
     */
    getWeekNumber () {
        // Copy date so don't modify original
        const d = new Date(Date.UTC(this.inputTime.getFullYear(), this.inputTime.getMonth(), this.inputTime.getDate()))
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
        // Get first day of year
        const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
        // Calculate full weeks to nearest Thursday and return it
        return Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
    }
}

module.exports = DateTime
