<template>
  <div class="row">
    <div class="col-12">
      <div
        class="row justify-content-center"
      >
        <div class="col-xl-10 col-lg-10  col-md-12">
          <h2>{{ `title_gm` | trans }}</h2>
        </div>
        <div class="col-xl-10 col-lg-10 col-md-10">
          <!-- Download -->
          <div class="col-lg-12 warcraft_article">
            <mail-management />
          </div>

          <!-- Setup -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
'use strict'
import { Common } from "@/common"
import MailManagement from "@/components/modules/gm/MailManagement.vue"

export default {
    components: { MailManagement },
    metaInfo () {
        return Common.getMetaInfo(this.$route.name)
    }
}
</script>
