import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookie'
import myStore from './store/store.js'

Vue.use(VueCookies)
Vue.use(VueRouter)

const pageLang = document.documentElement.lang

const router = new VueRouter({
    mode: `history`,
    routes: [
        {
            path: `/` + pageLang + `/`,
            component: require(`./components/modules/home/Home.vue`).default,
            name: `main_home`,
            pathToRegexpOptions: { strict: true }
        },
        {
            path: `/` + pageLang + `/download/`,
            component: require(`./components/modules/game/DownloadPage.vue`).default,
            name: `main_download`,
            pathToRegexpOptions: { strict: true },
            beforeEnter: (to, from, next) => {
                if (myStore.getters.getUser.username) {
                    next()
                } else {
                    next(`/` + pageLang + `/`)
                }
            }
        },
        {
            path: `/` + pageLang + `/discord/`,
            component: require(`./components/modules/game/DiscordIframe.vue`).default,
            name: `discord_frame`,
            pathToRegexpOptions: { strict: true }
        },
        {
            path: `/` + pageLang + `/gm/`,
            component: require(`./components/modules/gm/GameMaster.vue`).default,
            name: `main_gm`,
            pathToRegexpOptions: { strict: true },
            beforeEnter: (to, from, next) => {
                if (myStore.getters.getUser.username && myStore.getters.getUser.gmlevel) {
                    next()
                } else {
                    next(`/` + pageLang + `/`)
                }
            }
        },
        {

            path: `/` + pageLang + `/forum/`,
            component: {
                render (c) {
                    return c(`router-view`)
                }
            },
            children: [
                {
                    path: ``,
                    component: require(`./components/modules/forum/Forum.vue`).default,
                    name: `main_forum`
                },
                {
                    path: `topic/new/`,
                    component: require(`./components/modules/forum/NewTopic.vue`).default,
                    name: `forum_topic_new`
                },
                {
                    path: `topic/:topic_slug/:id/`,
                    component: require(`./components/modules/forum/ReadTopic.vue`).default,
                    name: `forum_topic`,
                    pathToRegexpOptions: { strict: true }
                }
            ]
        },
        {
            path: `/` + pageLang + `/shop/`,
            component: require(`@/components/modules/services/PlayerServices`).default,
            name: `main_shop`,
            pathToRegexpOptions: { strict: true },

            beforeEnter: (to, from, next) => {
                if (myStore.getters.getUser.username) {
                    next()
                } else {
                    next(`/` + pageLang + `/`)
                }
            }
        },
        {
            path: `/` + pageLang + `/login/`,
            component: require(`./components/modules/login/Login.vue`).default,
            name: `auth_login`
        },
        {
            path: `/` + pageLang + `/login/forgotten/`,
            component: {
                render (c) {
                    return c(`router-view`)
                }
            },
            children: [
                {
                    path: ``,
                    component: require(`./components/modules/login/LoginForgotten.vue`).default,
                    name: `login_forgotten_index`
                },
                {
                    path: `:key_pass/`,
                    component: require(`./components/modules/login/LoginForgottenKey.vue`).default,
                    name: `login_forgotten_key_pass`
                }
            ]
        },
        {
            path: `/` + pageLang + `/register/`,
            component: require(`./components/modules/login/Register.vue`).default,
            name: `auth_register`
        },
        {
            path: `/` + pageLang + `/settings/`,
            component: require(`./components/modules/login/UserSettings.vue`).default,
            name: `user_settings`
        },
        {
            path: `/` + pageLang + `/validate_account`,
            component: require(`./components/modules/login/ValidateAccount.vue`).default,
            name: `validate_account`
        },
        {
            path: `/` + pageLang + `/legal/`,
            component: require(`./components/modules/login/Legal.vue`).default,
            name: `legal`
        },
        {
            path: `/404`, // This will catch all navigation that doesn't match
            name: `error_not_found`,
            component: require(`./components/errors/NotFound.vue`).default
        },
        {
            path: `/401`, // This will catch all navigation that doesn't match
            name: `error_unauthorized`,
            component: require(`./components/errors/UnAuthorized.vue`).default
        },
        {
            path: `/:catchAll(.*)`, // This will catch all navigation that doesn't match
            name: `error_global`,
            component: require(`./components/errors/NotFound.vue`).default
        }
    ]
})

router.beforeEach((to, from, next) => {
    router.fromRoute = from.name
    return next()
})

export default router
