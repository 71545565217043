/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { myAxios } from '@/custom_axios'

export class TopicsApi {
    /**
   * Return current account
   * @returns {Promise<*>}
   */
    static async getTopics () {
        return myAxios.get(`/api/forum/1`)
    }

    /**
     * Return the specified topic
     * @param topicId
     * @return {Promise<void>}
     */
    static async getTopic (topicId) {
        return myAxios.get(`/api/forum/topic/${topicId}`)
    }

    /**
     * Returns the last pinned topic
     * @return {Promise<void>}
     */
    static async geLastPinnedTopic () {
        return myAxios.get(`/api/forum/last-pinned-topic`)
    }

    /**
     *
     * @param postData
     * @return {Promise<*>}
     */
    static async postTopic (postData) {
        return myAxios.post(`/api/forum/topic`, postData)
    }

    /**
     * Update statue of topic
     * @param {int} topicId
     * @param {{}} topicPatch
     * @return {Promise<*>}
     */
    static async patchTopic (topicId, topicPatch) {
        return myAxios.patch(`/api/forum/topic/${topicId}`, topicPatch)
    }

    /**
     *
     * @param postData
     * @return {Promise<*>}
     */
    static async postPost (postData) {
        return myAxios.post(`/api/forum/post`, postData)
    }

    /**
     *
     * @param postData
     * @return {Promise<*>}
     */
    static async hidePost (postData) {
        return myAxios.patch(`/api/forum/post/` + postData.post_id, postData)
    }
}
