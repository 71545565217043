/* jshint esversion: 6 */
/* jslint node: true */
"use strict"

const jsonValidationErrors = {
    invalid_email_format: {
        en: `Wrong e-mail format`,
        fr: `Adresse mail non valide`
    },

    invalid_only_letters: {
        en: `Username must be alphabetical.`,
        fr: `Le nom d'utilisateur doit être alphabétique.`,
    },
    
    invalid_length_4_16: {
        en: `Username must be up to 16 characters`,
        fr: `Le nom d'utilisateur doit faire jusqu'à 16 caractères`,
    }
}

module.exports = {
    test: {
        en: `TEST_EN`,
        fr: `TEST_FR`
    },
    home: {
        en: `Home`,
        fr: `Accueil`
    },

    home_title: {
        en: `Welcome to Murloc Village`,
        fr: `Bienvenue sur Murloc Village`
    },

    home_description_one: {
        en: `Murloc Village is a private evolving server of World of Warcraft from`,
        fr: `Murloc Village est un serveur privé World of Warcraft évolutif de `
    },

    home_description_two: {
        en: `vanilla`,
        fr: `vanilla`
    },

    home_description_three: {
        en: ` to `,
        fr: ` à `
    },

    home_description_four: {
        en: `Wrath of the Lich King`,
        fr: `Wrath of the Lich King`
    },

    home_description_five: {
        en: `. Our objectif is to promote fairplay and teamwork.`,
        fr: `. Notre objectif est de favoriser l'entraide et le jeu de groupe.`
    },

    home_spec_title: {
        en: `Server particularity`,
        fr: `Spécificités du serveur`
    },

    home_description_title_talent: {
        en: `Quests for talent points`,
        fr: `Quête pour points de talent`
    },

    home_description_summery_talent: {
        en: `Talent points are gained by group and class quests`,
        fr: `Les points de talent sont obtenus en accomplissant des quêtes de groupe ou de classe`
    },

    home_description_title_profession: {
        en: `Three primary professions`,
        fr: `Trois métiers primaires`
    },

    home_description_summery_profession: {
        en: `Besides secondaries professions, you can have up to 3 primary professions.`,
        fr: `En plus des métiers secondaires, vous pouvez choisir jusqu'à 3 métiers primaires.`
    },

    home_description_title_shop: {
        en: `Free shop`,
        fr: `Boutique gratuite`
    },

    home_description_summery_shop: {
        en: `Our shop sells services and cosmetic product in exchange of only votes for our server.`,
        fr: `Notre boutique vend produits cosmétique et services contre uniquement les votes pour notre serveur.`
    },

    home_description_title_transmog: {
        en: `Transmogrification`,
        fr: `Transmogrification`
    },

    home_description_summery_transmog: {
        en: `Change your equipment appearance at your favorite consortium agent in Exodar or Silvermoon.`,
        fr: `Changez l'apparence de votre armure auprès de votre agent du consortium préféré dans l'Exodar ou Lune d'Argent.`
    },

    home_description_title_ah: {
        en: `International Auction House & Postal services`,
        fr: `Hôtel de Vente et courrier Interfaction`
    },

    home_description_summery_ah: {
        en: `Auction houses and postal services are managed by Steamwheedle's goblins, without distinction between orc and humans.`,
        fr: `Les services d'hôtel de vente et postier sont désormais gérés par les gobelins de Gentepression, ne faisant aucune distinction entre orc et humain.`
    },

    home_description_title_group: {
        en: `Interfaction party`,
        fr: `Groupe interfaction`
    },

    home_description_summery_group: {
        en: `Alliance and Horde adventurers can collaborate to fight greater evil. I repeat, "can".`,
        fr: `Possibilité de faire front commun entre Alliance et Horde contre les ennemis d'Azeroth (je répète: "possibilité").`
    },

    home_description_title_bg: {
        en: `Battlefield on order`,
        fr: `Champs de bataille sur commande`
    },

    home_description_summery_bg: {
        en: `The requested number of players are lowered for each battlefield: Warsong -> 3 players by faction, Arathi -> 5 players by faction and Alterac -> 10 players by faction.`,
        fr: `Le nombre de joueurs prérequis pour les champs de bataille est diminué: Chanteguerre -> mini 3 vs 3, Arathi -> mini 5 vs 5 et Alterac ->  mini 10 vs 10.`
    },

    home_description_title_vanilla: {
        en: `WoW Classic witch class`,
        fr: `WoW Classic avec classe`
    },

    home_description_summery_vanilla: {
        en: `Blood Elf and Draenei are now available.`,
        fr: `WoW classic avec Elfe de Sang et Draenei en race disponible.`
    },

    home_spec_profession_boost: {
        en: `Weekend boost for professions and weapons skills`,
        fr: `Bonus de progression des compétences de métiers et d'armes le weekend`
    },

    auth_login_title: {
        en: `Connection`,
        fr: `Connexion`
    },

    auth_register_title: {
        en: `Subscribe`,
        fr: `S'inscrire`
    },

    auth_register_title2: {
        en: `Create an account`,
        fr: `Créer un compte`
    },

    auth_register_description: {
        en: `You have no account ?`,
        fr: `Vous n’avez pas de compte ?`
    },

    auth_register_email_policy: {
        en: `We'll never share your email with anyone else.`,
        fr: `Votre email ne sera jamais transféré`
    },

    main_download_title: {
        en: `Download`,
        fr: `Télécharger`
    },

    main_download_description: {
        en: `Download and setup the server to ur servers`,
        fr: `Télécharger, installer et configurer World of Warcraft pour vous connecter à nos serveurs`
    },

    main_forum_title: {
        en: `Forum`,
        fr: `Forum`
    },

    main_forum_general: {
        en: `General discussion`,
        fr: `Discussion générale`
    },

    main_forum_description: {
        en: `A question about the game, an idea of improvement, a bug ? Tell us we will help you`,
        fr: `Une question sur le jeu, une idée d'amélioration, un bug ou un problème inattendu ? Posez-nous votre question.`
    },

    main_shop_title: {
        en: `Shop`,
        fr: `Boutique`
    },

    main_gm_title: {
        en: `GM`,
        fr: `GM`
    },

    main_shop_description: {
        en: `Buy levels, mounts and pets.`,
        fr: `Achetez de niveaux, des montures ou des mascottes.`
    },

    lbl_account_name: {
        en: `Account name`,
        fr: `Nom de compte`
    },

    lbl_email: {
        en: `E-mail @`,
        fr: `E-mail @`
    },

    lbl_password: {
        en: `Password`,
        fr: `Mot de pass`
    },

    lbl_password_check: {
        en: `Check your password`,
        fr: `Confirmez votre mot de passe`
    },

    lbl_old_password: {
        en: `Old password`,
        fr: `Ancien mot de pass`
    },

    terms_of_service: {
        en: `Terms of service`,
        fr: `Condition d'utilisiation`
    },

    lbl_legal_check: {
        en: `I agree to terms`,
        fr: `Vous comprennez et acceptez nos conditions générales d'utilisation.`
    },

    btn_reset: {
        en: `Reset`,
        fr: `Effacer`
    },

    btn_submit: {
        en: `Submit`,
        fr: `Envoyer`
    },

    title_change_password: {
        en: `Change your password`,
        fr: `Changez votre mot de pass`
    },

    error_login_failed: {
        en: `Login or password incorrect`,
        fr: `Nom d'utilisateur ou mot de passe incorrect`
    },

    error_legal_check: {
        en: `I agree to terms`,
        fr: `Vous comprennez et acceptez nos conditions générales d'utilisation.`
    },


    error_wrong_password_no_match: {
        en: `Wrong password`,
        fr: `Mot de passe erroné`
    },

    error_account_already_exists: {
        en: `Account or Email already used`,
        fr: `Compte ou Email déjà utilisé`
    },

    logout_title: {
        en: `Log out`,
        fr: `Déconnection`
    },

    error_wrong_sum: {
        en: `Wrong sum`,
        fr: `Somme incorrect`
    },

    validate_account: {
        en: `Please check your e-mail too validate your account`,
        fr: `Consultez vos e-mails afin de valider votre compte.`
    },

    validate_account_done: {
        en: `Your accounte is validated`,
        fr: `Votre compte est valide.`
    },

    auth_lost_authentication: {
        en: `Login or password lost ?`,
        fr: `Compte ou mot de passe perdue ?`
    },

    class_1: {
        en: `Warrior`,
        fr: `Guerrier`
    },

    class_2: {
        en: `Paladin`,
        fr: `Paladin`
    },

    class_3: {
        en: `Hunter`,
        fr: `Chasseur`
    },

    class_4: {
        en: `Rogue`,
        fr: `Voleur`
    },

    class_5: {
        en: `Priest`,
        fr: `Prêtre`
    },

    class_6: {
        en: `Death Knight`,
        fr: `Chevalier de la Mort`
    },

    class_7: {
        en: `Shaman`,
        fr: `Chaman`
    },

    class_8: {
        en: `Mage`,
        fr: `Mage`
    },

    class_9: {
        en: `Warlock`,
        fr: `Démoniste`
    },

    class_10: {
        en: `NONE`,
        fr: `NONE`
    },

    class_11: {
        en: `Druid`,
        fr: `Druide`
    },

    label_level: {
        en: `level`,
        fr: `niveau`
    },

    link_new_topic: {
        en: `New Topic`,
        fr: `Nouveau sujet`
    },

    forum_message_title_label: {
        en: `Topic title`,
        fr: `Titre du sujet`
    },

    forum_topic_button_pinned_false: {
        en: `Pin`,
        fr: `Épingler`
    },

    forum_topic_button_locked_false: {
        en: `Lock`,
        fr: `Verrouiller`
    },

    forum_topic_button_hidden_false: {
        en: `Delete`,
        fr: `Supprimer`
    },

    forum_topic_button_pinned_true: {
        en: `Unpin`,
        fr: `Dépingler`
    },

    forum_topic_button_locked_true: {
        en: `Unlock`,
        fr: `Déverrouiller`
    },

    forum_topic_button_hidden_true: {
        en: `Undelete`,
        fr: `Rétablir`
    },

    forum_message_label: {
        en: `Message`,
        fr: `Message`
    },

    link_answer: {
        en: `Answer`,
        fr: `Répondre`
    },

    forum_message_submit: {
        en: `Submit`,
        fr: `Envoyer`
    },

    forum_message_delete: {
        en: `Delete`,
        fr: `Supprimer`
    },

    forum_message_undelete: {
        en: `Undelete`,
        fr: `Annuler la suppression`
    },

    forum_message_is_deleted: {
        en: `*This message has been deleted*`,
        fr: `*Ce message a été supprimé*`
    },

    title_game: {
        en: `Install the game`,
        fr: `Installer le jeu`
    },

    title_game_download: {
        en: `Download our version`,
        fr: `Voici les liens de téléchargement du jeu`
    },

    help_download: {
        en: `Install the game can be done with our launcher. In the following table you can download your required version of the game with the linked launcher. To start installation double click <strong>MV-Launcher</strong>.`,
        fr: `Pour jouer, décompressez le dossier et exécutez le launcher <strong>MV-Launcher</strong> ou l’exécutable <strong>Wow</strong>. 
            <br />
            Pour accéder aux nouveaux graphique en jeu, vous pourrez les installés via notre launcher.
            <br />
            <strong>
            Si possible ne laissez pas votre ordinateur en veille durant le téléchargement via le launcher.
            </strong>`
    },

    help_download_2: {
        en: `Take note that as you are on a private server our launcher, developped by ous will be tagged as unkown by Microsoft / Apple services. That's why you will have to approve the execution. Furthermore your antivirus will analyse the programe (it can take 30min). <br /> <small>Our launcher don't collect any data if you are a developper you can take a look on our code <a href="https://github.com/Miorey/wow-launcher">here</a></small>`,
        fr: `Etant un launcher édité par nos soins et donc inconnu par les services Microsoft et Apple, une autorisation de votre part est demandée à la première utilisation.`
    },

    help_download_3: {
        en: `Take note that as you are on a private server our launcher, developped by ous will be tagged as unkown by Microsoft / Apple services. That's why you will have to approve the execution. Furthermore your antivirus will analyse the programe (it can take 30min). <br /> <small>Our launcher don't collect any data if you are a developper you can take a look on our code <a href="https://github.com/Miorey/wow-launcher">here</a></small>`,
        fr: `Il est possible que votre antivirus analyse le launcher à la première utilisation. Dans ce cas veuillez patienter qu'il termine son inspection pour pouvoir l'exécuter (Cela peut prendre jusqu'à 30 minutes).
            Aucune donnée ne sera collectée via notre launcher, en cas de doute vous trouverez le code source à ce <a href="https://github.com/Miorey/wow-launcher">lien</a>
            <br /><br />En cas de problème ou pour tout autre interrogation, n'hésitez pas à nous solliciter sur le forum ou sur discord.
            <br /><br />Bon jeu !`
    },

    help_launcher_download: {
        en: `Install the game can be done with our launcher. In the following table you can download your required version of the game with the linked launcher. To start installation double click <strong>MV-Launcher</strong>.`,
        fr: `Si vous avez déjà une version du jeu, téléchargez le zip du launcher correspondant à votre système d'exploitation. <br /><br />Extraire les fichiers dans le dossier du jeu contenant l'exécutable du jeu Wow.exe. Sur Mac l'exécutable du jeu devra se nommer Wow.`
    },

    download_win_launcher: {
        en: `Windows Launcher`,
        fr: `Launcher Windows`
    },

    dl_game_fr: {
        en: `Game FR`,
        fr: `Jeu FR`
    },

    dl_game_en: {
        en: `Game EN`,
        fr: `Jeu EN`
    },

    help_start_game: {
        en: `Go in the unziped directory and execute Wow.exe.`,
        fr: `Allez dans le dossier précédemment décompressé  et exécutez Wow.exe`
    },

    title_game_setup: {
        en: `You already have a 3.5.5a version of the game.`,
        fr: `Mettre à jour le jeu (Wotlk 3.3.5)`
    },

    forum_topic_title: {
        en: ` `,
        fr: ` `
    },

    forum_post_submit: {
        en: `Submit topic`,
        fr: `Soumettre le sujet`
    },

    forum_error_post_too_short: {
        en: `The message should have at least 5 characters`,
        fr: `Le message dois contenir au minimum 5 characters`
    },

    forum_error_title_length: {
        en: `The title length should be between [10,64] characters`,
        fr: `Le titre dois contenir entre  [10,64] characters`
    },

    time_hour: {
        en: ` `,
        fr: ` `
    },

    time_days: {
        en: `days`,
        fr: `jours`
    },

    time_other: {
        en: ` `,
        fr: ` `
    },

    label_player_online: {
        en: `Player online`,
        fr: `En jeu`
    },

    success_password_change: {
        en: `Your password have been changed.`,
        fr: `Votre mot passe à être modifié.`
    },

    danger_password_change: {
        en: `Can't change your password.`,
        fr: `Changement de mot de pass impossible.`
    },

    error_wrong_password_same: {
        en: `Your password haven't been changed`,
        fr: `Votre mot de pass n'a pas changé`
    },

    main_home_title: {
        en: `News`,
        fr: `Actualités`
    },

    website_title: {
        en: `Murloc Village`,
        fr: `Murloc Village`
    },

    main_home_description: {
        en: `World of Warcraft private server`,
        fr: `Serveur privé WoW évolutif de Vanilla à Wrath of the Lich King (WoTLK) entièrement gratuit. Blizzlike, Rate XP +/- 1, Drop 1`
    },

    title_revive: {
        en: `Back to live`,
        fr: `Retour à la vie`
    },

    description_revive: {
        en: `You will be brought back to live`,
        fr: `Vos êtes coincé et aucun maître de jeu n'est disponible ? Ce sort vous permettra de débloquer.`
    },

    description_revive2: {
        en: `in the nearest graveyard for`,
        fr: `sera ressuscité et téléporter au cimetière le plus proche pour `
    },

    title_shopping: {
        en: `How to make shopping`,
        fr: `Comment faire du shopping`
    },

    description_shopping_1: {
        en: `Select the character in website menu.`,
        fr: `Sélectionnez votre personnage dans le menu du site.`
    },

    description_shopping_2: {
        en: `Connect you character in the game.`,
        fr: `Connectez-vous au personnage en jeu.`
    },

    description_shopping_3: {
        en: `Click the button below.`,
        fr: `Cliquez sur le bouton ci-dessous.`
    },

    info_revive: {
        en: `You will be back to live in 5 seconds`,
        fr: `Vous serrez vivant dans 5 secondes`
    },

    error_character_not_connected: {
        en: `You are not connected on the selected character.`,
        fr: `Vous n'êtes pas connecté sur le personnage sélectionné`
    },

    btn_revive: {
        en: `Revive`,
        fr: `Ange, fais ton office`
    },

    success_revive: {
        en: `You are alive !`,
        fr: `Vos êtes vivant !`
    },

    title_level: {
        en: `Buy level`,
        fr: `Achat de niveaux`
    },

    text_level_one: {
        en: `Gain level :level with a little help of `,
        fr: `Obtenez le niveau :level ainsi qu'une petite aide financière de `
    },

    text_level_two: {
        en: ` for `,
        fr: ` pour `
    },

    text_level_three: {
        en: `And this for :price`,
        fr: `Pour seulement :price`
    },

    text_level_max: {
        en: ` is level :level, the maximum you can buy is :max_level.`,
        fr: ` est niveau :level. Vous pouvez acheter au maximum :max_level niveaux.`
    },

    description_level_1: {
        en: `Select the character in website menu.`,
        fr: `Sélectionnez votre personnage dans le menu du site.`
    },

    description_level_2: {
        en: `Click the button below.`,
        fr: `Cliquez sur le bouton ci-dessous.`
    },

    info_level: {
        en: `INFO LEVEL IN ENGLISH`,
        fr: `INFO LEVEL IN FRENCH`
    },

    error_level: {
        en: `You'll be wiser to kill some dragon than some mug at the tavern !`,
        fr: `Profitez de l'occasion de gagner de l'expérience sur des dragons plutôt qu'en taverne !`
    },

    btn_level: {
        en: `Give me more power`,
        fr: `Donnez moi plus de puissance !`
    },

    success_level: {
        en: `You'r getting better at this !`,
        fr: `Vous avez pris un niveau.`
    },

    info_customize: {
        en: `Operation bloc occupied`,
        fr: `Bloc opératoire occupe`
    },

    error_money: {
        en: `Not enough golden lion to pay for this`,
        fr: `Solde insuffisant pour effectuer la transaction`
    },
    error_another_action_in_progress: {
        en: `Another action is in progress on this character`,
        fr: `Une autre action est en cours sur ce personnage`
    },

    mail_title_level: {
        en: `Knowledge improvement`,
        fr: `Acquisition de connaissance`
    },

    mail_body_level: {
        en: ` `,
        fr: ` `
    },

    error_unexpected: {
        en: `An unexpected error happens. Please describe your bug on the forum !`,
        fr: `Une erreur inattendue est survenue. Pourriez faire une description du bug sur le forum.`
    },

    money_name: {
        en: `GL`,
        fr: `LO`
    },

    link_forgotten: {
        en: `Forgotten password ?`,
        fr: `Mot de pass perdu?`
    },

    msg_init_password: {
        en: `Check your mails to reset your password`,
        fr: `Consultez vos mails afin de réinitialiser votre mot de pass`
    },

    msg_account_not_exists: {
        en: `This account/email does not exist`,
        fr: `L'email ou le compte n'existe pas.`
    },

    msg_init_password_done: {
        en: `Your password is has been reset`,
        fr: `Votre mot de pass à ete reinitialisé`
    },

    msg_no_reset_key: {
        en: `Your reset has expired please reset it again`,
        fr: `Votre clef de réinitialisation du mot de passe a expiré.`
    },

    services_title: {
        en: `Our services`,
        fr: `Nos services`
    },

    title_vote: {
        en: `Vote for us !`,
        fr: `Votez pour nous`
    },

    description_vote: {
        en: `Bribe for your vote:`,
        fr: `Pot-de-vin pour votre vote:`
    },

    next_vote: {
        en: `Next vote:`,
        fr: `Prochain vote: `
    },

    btn_vote: {
        en: `Vote`,
        fr: `Vote`
    },

    title_mount: {
        en: `My Kingdom for a Horse !`,
        fr: `Mon royaume pour un cheval !`
    },

    text_mount_one: {
        en: `Buy the week mount, `,
        fr: `Achetez la monture de la semaine, `
    },

    text_mount_two: {
        en: ` for`,
        fr: ` pour`
    },

    text_mount_three: {
        en: `It will cost you `,
        fr: `Cela vous coûtera la modique somme de `
    },

    mail_title_mount: {
        en: `Stable service`,
        fr: `Service d'étable`
    },

    mail_body_mount: {
        en: `Hello follow rider !\n\nI got you our finest :mount for you !\nTake well of.`,
        fr: `Salutation ami aventurier !\n\nJ'ai pour vous notre plus rapide :mount pour vous !\nPrenez-en soin.`
    },

    title_rename: {
        en: `My Name Is Nobody`,
        fr: `Mon nom est Personne`
    },

    description_rename: {
        en: `We can offer you a new identity,`,
        fr: `Nous pouvons vous trouver une nouvelle identité :price`
    },

    description_rename_2: {
        en: `for tiny-ty price of`,
        fr: `, en échange d'une petite compensation de`
    },

    buy_rename: {
        en: `I sign under wich name?`,
        fr: `Je signe sous quel nom?`
    },

    mail_title_rename: {
        en: `Identity change`,
        fr: `Changement d'identité'`
    },

    mail_body_rename: {
        en: `Greetings :name.\n\nWe have reviewed your request for name change and are pleased to accepted it.\nYou will be able to register under a new name at your next visit.\nAttach to this letter your documents certifying your new identity`,
        fr: `Salutation :name.\n\nNous avons étudié votre requête de changement de nom et nous sommes heureux de vous l'accepter.\nVous pourrez vous enregistrer sous à nouveau nom à votre prochaine venue.\nJoint à cette lettre vos documents attestants votre nouvelle identité`
    },

    success_rename: {
        en: `Your rename request have been send`,
        fr: `Votre demande de changement de nom a été envoyée`
    },

    title_customize: {
        en: `Mirror, Mirror on the Wall`,
        fr: `Miroir, Ô mon beau miroir`
    },

    description_customize: {
        en: `TODO`,
        fr: `, vous êtes certainement la plus belle créature de ce royaume, mais pour`
    },

    description_customize2: {
        en: `TODO`,
        fr: `vous serez plus belle encore.`
    },

    buy_customize: {
        en: `I sign under wich name?`,
        fr: `Je signe sous quel nom?`
    },

    mail_title_customize: {
        en: `Identity change`,
        fr: `Changement d'identité'`
    },

    mail_body_customize: {
        en: `Greetings :name.\n\nWe have reviewed your request for name change and are pleased to accepted it.\nYou will be able to register under a new name at your next visit.\nAttach to this letter your documents certifying your new identity`,
        fr: `Salutation :name.\n\nNous avons étudié votre requête de changement de nom et nous sommes heureux de vous l'accepter.\nVous pourrez vous enregistrer sous à nouveau nom à votre prochaine venue.\nJoint à cette lettre vos documents attestants votre nouvelle identité`
    },

    success_customize: {
        en: `You are the crucifix of Venus.`,
        fr: `Venus et Apolon vont rester béat devant tant de beauté.`
    },

    description_changerace: {
        en: `,  don't you feel comfortable in your own skin? For just`,
        fr: `, vous ne vous sentez pas bien dans votre peau ? Pour seulement `
    },

    description_changerace2: {
        en: `, you'll be able to try someone else's.`,
        fr: `, vous pourrez essayer celle d'un autre.`
    },

    mail_title_changerace: {
        en: `Identity change`,
        fr: `Changement d'identité'`
    },

    mail_body_changerace: {
        en: `Greetings :name.\n\nYou can leave our laboratory with confidence. Please do not hesitate to contact my secretary in case of any side effects.\nYou will be able to register under a new name at your next visit.\nAttach to this letter your documents certifying your new identity`,
        fr: `Salutation :name.\n\nVous pouvez quitter notre laboratoire en toute sérénité. N'hésitez pas à contacter ma secrétaire en cas d'effets secondaires.\nJoint à cette lettre vos documents attestants votre nouvelle identité`
    },

    success_changerace: {
        en: `The surgical alteration will take place.`,
        fr: `Le changement va s'opérer.`
    },

    description_changefaction: {
        en: `, your companions smell like jackals? their odor disgusts you? By passing through the surgical institute of Booty Bay, you can join the opposing faction. For only`,
        fr: `, vos compagnons sentent le chacal? leur odeur vous insupporte? En passant par l'institut chirurgical de la Baie-du-Butin, vous pourrez rejoindre la faction adverse. Pour seulement `
    },

    description_changefaction2: {
        en: `, you can join the opposing faction.`,
        fr: `, vous pourrez rejoindre la faction adverse.`
    },

    mail_title_changefaction: {
        en: `Identity change`,
        fr: `Changement d'identité'`
    },

    mail_body_changefaction: {
        en: `Greetings :name.\n\nYHere is your new identity. Never mention your origins if you don't want to end up on the scaffold.`,
        fr: `Salutation :name.\n\nVoici votre nouvelle identité. Ne mentionnez jamais vos origines si vous ne voulez pas finir sur l'échafaud.`
    },

    success_changefaction: {
        en: `Treason in progress.`,
        fr: `Trahison en cours d'exécution`
    },

    btn_buy: {
        en: `I buy`,
        fr: `J'achète`
    },

    success_mount: {
        en: `Your mount has been sended`,
        fr: `Votre monture a bien été envoyée`
    },

    title_pet: {
        en: `My town for a puppy !`,
        fr: `Mon village pour un chiot !`
    },

    text_pet_one: {
        en: `Buy the week pet, `,
        fr: `Achetez le familier de la semaine, `
    },

    text_pet_two: {
        en: ` for `,
        fr: ` pour `
    },

    text_pet_three: {
        en: `It will cost you `,
        fr: `Cela vous coûtera la somme dérisoire de `
    },

    success_pet: {
        en: `Your pet has been sended`,
        fr: `Votre familier a bien été envoyée`
    },

    mail_title_pet: {
        en: `BoxNews`,
        fr: `Service de livraison`
    },

    mail_body_pet: {
        en: `Hello :name,\n\nHere your new little friend, for further information, please refer directly to him/her/it.`,
        fr: `Bonjour :name,\n\nVoici votre colis, la notice d'utilisation est incorporée à l'objet.`
    },

    status_server_online: {
        en: `Server online`,
        fr: `Serveur en ligne`
    },

    status_server_down: {
        en: `Server down`,
        fr: `Serveur hors ligne`
    },

    title_gm: {
        en: `Game master's guide`,
        fr: `Guide du maître de jeu`
    },

    label_perm_name: {
        en: `Permission`,
        fr: `Permission`
    },
    label_command_name: {
        en: `Command`,
        fr: `Commande`
    },
    label_command_help: {
        en: `Description`,
        fr: `Description`
    },
    label_perm_id: {
        en: `ID`,
        fr: `ID`
    },
    label_download_gm_genie: {
        en: `GM Genie is as very useful Addon for GM`,
        fr: `GM Genie est un Addon très utiles pour les maître de jeu`
    },
    download_gm_genie: {
        en: `Download`,
        fr: `Télécharger`
    },
    love_is_in_the_air_title: {
        en: `Love is in the Air`,
        fr: `De l'amour dans l'air, je t'aime, je te tue.`
    },
    love_is_in_the_air_description: {
        en: `Love is in the Air`,
        fr: `Affrontez l'apothicaire Hummel et ses acolytes dans un combat tendre et chaleureux jusqu'à ce que mort s'ensuive.`
    },
    title_discord: {
        en: `Discord server`,
        fr: `Serveur Discord`
    },
    ...jsonValidationErrors

}
