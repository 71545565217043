<template>
  <div>
    <nc-link
      v-if="getUser && (!topic.locked || (getUser.gmlevel && getUser.gmlevel > 0))"
    >
      <form
        id="forum_post"
        method="post"
        @submit.prevent="onSubmit"
      >
        <div
          class="forum_form row"
        >
          <div class="col-3">
            &nbsp;
          </div>
          <div class="col-9">
            <post-text-area :errors="errors" />
          </div>
          <div class="col-3">
            &nbsp;
          </div>
          <div
            class="col-9"
          >
            <input
              :value="topic.id"
              type="hidden"
              name="post_topic_id"
            >
            <button
              class="float-right btn warcraft_btn"
            >
              {{ 'forum_message_submit' | trans }}
            </button>
          </div>
        </div>
      </form>
    </nc-link>
  </div>
</template>
<script>

import NcLink from "@/components/ui/NcLink.vue"
import { TopicsApi } from "@/api/TopicsApi"
import PostTextArea from "@/components/modules/forum/PostTextArea.vue"
import { formDataToJson } from "@/libs/formDataUtils"

export default {
    components: { PostTextArea, NcLink },
    props: {
        topic: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            errors: {},
            message: ``,
            isSubmitted: false
        }
    },
    computed: {
        getUser () {
            return this.$store.getters.getUser
        }
    },
    methods: {

        async onSubmit () {
            try {
                const form = document.querySelector(`#forum_post`)
                const formData = new FormData(form)
                const postData = formDataToJson(formData)
                const newPost = await TopicsApi.postPost(postData)
                document.getElementById(`post_message`).innerText = ``
                this.$emit(`new-post`, newPost.data)
                this.errors = {}
                this.message = ``
            } catch (error) {
                this.errors = error.response.data
                this.isSubmitted = false
            }
        }
    }
}
</script>
<style scoped>

</style>
