"use strict"

const DateTime = require(`./DateTime`)

const ncCharacter = () => {
    return {
        class: 1,
        name: `Anonymous`,
        level: 13
    }
}

const ncAccount = () => {
    return {
        id: 0
    }
}

/**
 *
 * @return {{maxLevel: number, voteReward: number, rename_item: number, rename: number, revive: number, customize: number}}
 */
const serviceConf = {
    rename_item: 11942,
    maxLevel: 55,

    // vote reward
    voteReward: 5,

    // prices
    revive: 25,
    customize: 1250,
    changerace: 2000,
    changefaction: 2500,
    rename: 1000
}

/**
 *
 * @param items {[]}: List of mounts object
 * @param type {string}: type of item to search (mount, pet, toy)
 * @param id {number}: Account id
 * @return {{}} : The available item
 */
const currentItem = (items, type, id) => {
    const currentItems = items.filter(item => item.item_type === type)
    const specialItems = currentItems.filter(item => item.special !== null)

    // diablo
    const result = specialItems.find(e => e.item_type === type && e.special === new Date().getDate())

    if (result) {
        return result
    }
    const genericItems = currentItems.filter(item => item.special === null)
    if (genericItems.length === 0) {
        return null
    } else {
        const dateTime = new DateTime(new Date())
        return genericItems[(id + dateTime.getWeekNumber()) % genericItems.length]
    }
}

/**
 * Money received per level
 * @param {int} level
 * @return {number}
 */
const nextLevelToMoney = (level) => {
    return level * (level + 1) * 66
}

/**
 * Cost to buv next level
 * @param level {number}: Current level
 * @return {number}
 */
const nextLevelCost = (level) => {
    return (level + 1) * 2
}

/**
 *
 * @param {int} copper
 * @return {{gold: number, copper: number, silver: number}}
 */
const copperToWowMoney = (copper) => {
    return {
        copper: copper % 100,
        silver: Math.trunc((copper / 100) % 100),
        gold: Math.trunc(copper / 10000)
    }
}

module.exports = {
    copperToWowMoney,
    nextLevelCost,
    nextLevelToMoney,
    currentItem,
    serviceConf,
    ncAccount,
    ncCharacter,
    DateTime
}
