<template>
  <span>
    {{ copperToMoney.gold }}<img src="/images/gold.webp">
    {{ copperToMoney.silver }}<img src="/images/silver.webp">
    {{ copperToMoney.copper }}<img src="/images/copper.webp">
  </span>
</template>

<script>
const { copperToWowMoney } = require(`character-services`)
export default {
    name: `WowMoney`,
    props: {
        money: {
            type: Number,
            required: true
        }
    },
    computed: {
        copperToMoney () {
            return copperToWowMoney(this.money)
        }
    }

}
</script>
