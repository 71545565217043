<template>
  <div class="row">
    <div class="col-12">
      <div class="row justify-content-center">
        <div
          v-if="topic.id"
          class="col-xl-10 col-lg-10  col-md-12"
        >
          <div class="row">
            <div class="col-12 col-md-6">
              <h2>
                <svg-icon
                  v-if="topic.pinned"
                  icon-svg="bell"
                  fill="#f8b700"
                />
                <svg-icon
                  v-if="topic.locked"
                  icon-svg="lock_empty"
                  fill="#f8b700"
                />
                <svg-icon
                  v-if="topic.hidden"
                  icon-svg="trash"
                  fill="#f8b700"
                />
                {{ topic.title }}
              </h2>
            </div>
          </div>
          <div
            v-for="(post, postKey) in topic.posts"
            :key="postKey"
            class="row topic_message"
          >
            <div class="col-3 forum_author">
              <strong class="topic_message_author">
                <race-icon
                  :gender="post.author.gender"
                  :race="post.author.race"
                />
                <span>{{ post.author.name }}</span>
              </strong>
              <br>
              <span :class="'class_'+post.author.class">
                {{ ('class_' + post.author.class) | trans }}
                {{ 'label_level' | trans }} {{ post.author.level }}
              </span>
              <br>
              <span>
                {{ (new DateTime(post.created_at)).returnDiff().time }}
                {{ (new DateTime(post.created_at)).returnDiff().dictionary_key | trans }}
              </span>
              <br>
              <form
                v-if="allowDeletion(post, topic.posts.length, postKey)"
                id="forum_delete"
                @submit.prevent="onDelete"
              >
                <button
                  class="btn warcraft_btn"
                  :value="post.id"
                  name="post_id"
                >
                  <input
                    type="hidden"
                    :value="!post.hidden"
                    name="post_to_hide"
                  >
                  <span
                    v-if="!post.hidden"
                  >
                    {{ 'forum_message_delete' | trans }}
                  </span>
                  <span
                    v-else
                  >
                    {{ 'forum_message_undelete' | trans }}
                  </span>
                </button>
              </form>
            </div>
            <div
              class="col-9 forum_message"
            >
              <p
                :class="(post.is_gm)?'forum_message_admin':' '"
              >
                {{ post.text }}
              </p>
              <p
                v-if="post.hidden"
                :class="'forum_message_admin'"
              >
                {{ `forum_message_is_deleted` | trans }}
              </p>
            </div>
          </div>
          <br>

          <write-message
            :topic="topic"
            @new-post="addNewPost"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { TopicsApi } from "@/api/TopicsApi"
import RaceIcon from "../../ui/RaceIcon"
import SvgIcon from "@/components/ui/SvgIcon.vue"
import WriteMessage from "@/components/modules/forum/WriteMessage.vue"

const { DateTime } = require(`character-services`)

export default {
    metaInfo () {
        return {
            title: this.topic.title
        }
    },
    components: { WriteMessage, SvgIcon, RaceIcon },
    data () {
        return {
            errors: {},
            message: ``,
            topic: {},
            isSubmitted: false,
            DateTime
        }
    },

    computed: {

        getUser () {
            return this.$store.getters.getUser
        }
    },

    mounted () {
        const topicId = this.$router.currentRoute.params.id
        this.getTopic(topicId).catch(() => {
            console.error(`Topic ID ${topicId} not exists`)
        })
    },

    methods: {
        addNewPost (data) {
            this.topic.posts.push(data)
        },
        async getTopic (idTopic) {
            const response = await TopicsApi.getTopic(idTopic)
            document.title = document.title + ` ` + response.data.title
            this.topic = response.data
        },

        /**
     * *
     * Show the delete button for GM or last person whom responded
     * @param post
     * @param topicLength (int)
     * @param postKey (int)
     * @return boolean
     * *
     * TODO add deletion for last person whom responded
     * *
     */
        allowDeletion (post, topicLength, postKey) {
            const user = this.$store.getters.getUser
            if (user) {
                if (user.gmlevel && user.gmlevel > 0) {
                    return true
                } else if (
                    postKey === topicLength - 1 &&
                    (new DateTime(post.updated_at)).diffMin() < 30.0 &&
                    user.username === post.author) {
                    return false
                }
            }
            return false
        },

        /**
     * Patch post to delete(hide) or undelete it
     * @return {Promise<void>}
     */
        async onDelete () {
            try {
                const deleteData = {}
                const response = await TopicsApi.hidePost(deleteData)
                if (response.status === 200) {
                    this.topic.posts.find(post => post.id === response.data.id).hidden = response.data.hidden
                    if (!this.topic.posts.filter(e => !e.hidden).length) {
                        this.topic.hidden = true
                    }
                }
            } catch (error) {
                this.errors = error.response.data
                this.isSubmitted = false
            }
        }
    }
}

</script>
