<template>
  <div class="col-lg-10 warcraft_article p-3 mb-4">
    <div
      v-if="pets"
      class="row"
    >
      <div class="col-md-8 col-sm-12">
        <h3>{{ `title_pet` | trans }}</h3>
        {{ `text_pet_one` | trans }}
        <a
          :data-wowhead="`item=${currentPet.item}`"
          :href="currentPet.http_link"
          data-entity="item"
          data-wh-icon-size="small"
        >
          {{ currentPet.item_name }}
        </a>
        {{ `text_pet_two` | trans }}
        <character-name :character="character" />
        .<br>
        {{ `text_pet_three` | trans }}
        {{ currentPet.price }}
        <img
          alt="wine"
          src="/images/wine.webp"
        >
        <br><br>

        <nc-link :no-button="true">
          <form
            method="post"
            @submit.prevent="onSubmit"
          >
            <button
              :disabled="isSubmitted"
              class="btn warcraft_btn"
              type="submit"
            >
              {{ 'btn_buy'|trans }}
            </button>
          </form>
        </nc-link>
      </div>
      <div
        ref="image_container"
        class="col-md-4 d-none d-md-block"
      >
        <img
          :src="`/images/${currentPet.item}.webp`"
          alt="Pet"
          class="mx-auto d-block img-fluid"
        >
      </div>
    </div>
  </div>
</template>

<script>

import { ServicesApi } from "@/api/ServicesApi"
import NcLink from "../../ui/NcLink.vue"
import CharacterName from "@/components/ui/CharacterName"
import { currentItem } from 'character-services'

export default {
    components: { CharacterName, NcLink },
    data () {
        return {
            pets: undefined,
            isSubmitted: false
        }
    },
    computed: {

        getUser () {
            return this.$store.getters.getUser
        },

        account () {
            return this.$store.getters.getServiceAccount
        },

        character () {
            return this.$store.getters.getServiceCharacter
        },

        currentPet () {
            return currentItem(this.pets, `pet`, this.account.id)
        }
    },

    async mounted () {
        await this.loadPets()
    },

    methods: {
        async loadPets () {
            const response = await ServicesApi.getItems()
            this.pets = response.data
        },

        async onSubmit () {
            this.isSubmitted = true
            await this.$store.dispatch(`addAlert`, { key: `info_revive`, type: `info` })
            try {
                await ServicesApi.putPet()
                // Remove fail message if exists
                await this.$store.dispatch(`addAlert`, { key: `success_pet`, type: `success` })
                await this.$store.dispatch(`loadAccount`)
            } catch (error) {
                this.isSubmitted = false
                // Connection fail message
                let errorMessage = `error_character_not_connected`

                if (error.response.status === 500) {
                    errorMessage = `error_unexpected`
                } else if (error.response.status === 402) {
                    errorMessage = `error_money`
                }

                await this.$store.dispatch(`addAlert`, {
                    key: errorMessage,
                    type: `danger`
                })
            } finally {
                this.isSubmitted = false
                await this.$store.dispatch(`delAlert`, `info_revive`)
            }
        }
    }
}
</script>
