<template>
  <!-- Logout Menu -->
  <ul
    class="nav navbar-nav menu menu_right"
  >
    <li
      class="nav-item dropdown"
      tabindex="1"
    >
      <span
        v-if="selectedCharacter"
        id="account_nav_bar"
        class="nav-link dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span class="link">{{ selectedCharacter.name }}</span><br>
        <span :class="'class_'+selectedCharacter.class">{{
          ('class_' + selectedCharacter.class) | trans
        }} {{ 'label_level' | trans }} {{ selectedCharacter.level }}</span>
      </span>
      <span
        v-else
        id="account_nav_bar"
        class="nav-link dropdown-toggle link"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        @click="listCharacters()"
      >
        <span>Dropdown link</span>
      </span>

      <div
        class="dropdown-menu"
        aria-labelledby="account_nav_bar"
      >
        <ul>
          <li
            v-for="(character, characterKey) in getCharacters"
            :key="characterKey"
            class="nav-item"
            @click="switchCharacter(character)"
          >
            <a class="nav-link">
              <span>{{ character.name }}</span>
            </a>
            <span :class="'class_'+character.class">{{ ('class_' + character.class) | trans }} {{
              'label_level' | trans
            }} {{ character.level }}</span>
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              @click="logout()"
            >{{ 'logout_title' | trans }}</a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
import { CharactersApi } from "@/api/CharactersApi"

export default {
    name: `LoggedMenu`,

    data () {
        return {
            characters: []
        }
    },
    computed: {

        getUser () {
            return this.$store.getters.getUser
        },

        getCharacters () {
            return this.$store.getters.getCharacters
        },

        selectedCharacter () {
            return this.$store.getters.getCurrentCharacter
        }

    },

    mounted () {
        this.listCharacters()
    },

    methods: {
    /**
     *
     * @param {string}cookieName
     */
        deleteCookie (cookieName) {
            document.cookie = cookieName + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
        },

        /**
     * Call the logout api and forward to home page
     * @return {boolean}
     */
        async logout () {
            this.deleteCookie(`access_token`)
            this.deleteCookie(`username`)
            this.deleteCookie(`gmlevel`)
            this.deleteCookie(`mirror_token`)
            await this.$store.dispatch(`setUser`, {
                access_token: undefined,
                mirror_token: undefined,
                gmlevel: undefined,
                username: undefined
            })
            await this.$store.dispatch(`resetCharacters`)
            if (this.$router.currentRoute.name !== `main_home`) {
                await this.$router.push({ name: `main_home` })
            }
            return false
        },

        async switchCharacter (character) {
            await CharactersApi.putAccountCharacters(character)
            this.listCharacters()
        },

        /**
     * List the current user characters and set the selected one
     * @returns {boolean}
     */
        async listCharacters () {
            if (!this.getUser.username) {
                return false
            }
            await this.$store.dispatch(`loadCharacters`)
        }

    }
}
</script>
