<template>
  <div class="col-lg-12 warcraft_article p-3 mb-4">
    <div class="row">
      <div class="col-md-8 col-sm-12">
        <h3>{{ `title_game_setup` | trans }}</h3>
        <p v-html="$translator(`help_launcher_download`, {})" />
        <nc-link>
          <p>
            <a
              href="/dl/MV-Launcher.zip"
              class="btn warcraft_btn"
            >{{ 'download_win_launcher'|trans }}</a>
          </p>
        </nc-link>
      </div>
      <div class="col-md-4 d-none d-md-block">
        <image-auto-resize
          src="/images/pirate_2.webp"
          alt="Pirate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImageAutoResize from "../../ui/ImageAutoResize"
import NcLink from "../../ui/NcLink.vue"

export default {
    components: { NcLink, ImageAutoResize }
}
</script>
<style>
.realm_list_change {
  padding-left: 10%;
  background-color: #333;
  color: #fff;
}
</style>
