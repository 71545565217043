<template>
  <div>
    <div class="row justify-content-md-center">
      <div
        :id="$route.name+'_form'"
        class="col-lg-2 centered warcraft_form auth_form"
      >
        <form
          id="form_register"
          @submit.prevent="onSubmit"
        >
          <div class="form-group">
            <label
              v-if="errors.account"
              for="account"
              class="form-text text-danger"
            >* {{ errors.account | trans }}</label>
            <input
              id="account"
              v-model="account"
              :minlength="4"
              :maxlength="16"
              :disabled="isSubmitted"
              :placeholder="'lbl_account_name' | trans"
              name="account"
              required
              type="text"
              class="form-control"
            >

            <label
              v-if="errors.email"
              for="email"
              class="form-text text-danger"
            >* {{ errors.email | trans }}</label>
            <input
              id="email"
              v-model="email"
              :disabled="isSubmitted"
              :placeholder="'lbl_email' | trans"
              name="email"
              maxlength="255"
              required
              type="email"
              class="form-control"
              aria-describedby="register_email_help"
            >

            <label
              v-if="errors.password"
              for="password"
              class="form-text text-danger"
            >* {{ errors.password | trans }}</label>
            <input
              id="password"
              v-model="password"
              :minlength="4"
              :maxlength="16"
              :placeholder="'lbl_password' | trans"
              name="password"
              required
              type="password"
              class="form-control"
            >

            <label
              v-if="errors.password_check"
              for="password_check"
              class="form-text text-danger"
            >* {{ errors.password_check | trans }}</label>
            <input
              id="password_check"
              v-model="password_check"
              :minlength="4"
              :maxlength="16"
              :disabled="isSubmitted"
              :placeholder="'lbl_password_check' | trans"
              name="password_check"
              required
              type="password"
              class="form-control"
            >

            <app-captcha
              :errors="errors"
              :is-submitted="isSubmitted"
            />
            <label
              v-if="errors.legal"
              for="legal"
              class="form-text text-danger"
            >
              {{ `error_legal_check`|trans }}
            </label>
            <input
              id="legal"
              type="checkbox"
              name="legal"
              value="true"
              required
            >
            <span class="legal">
              {{ `lbl_legal_check`|trans }}
            </span>
            <br>
            <router-link
              :to="{ name: 'legal' }"
              class="warcraft_text"
            >
              {{ `terms_of_service` | trans }}
            </router-link>
          </div>
          <button
            :disabled="isSubmitted"
            type="submit"
            class="btn warcraft_btn"
          >
            {{ 'btn_submit' | trans }}
          </button>
          <br>&nbsp;
          <button
            :disabled="isSubmitted"
            type="reset"
            class="btn warcraft_btn"
          >
            {{ 'btn_reset' | trans }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import appCaptcha from './Captcha.vue'
import { AccountApi } from "@/api/AccountApi"
import { formDataToJson } from "@/libs/formDataUtils"

export default {

    components: { appCaptcha },

    data () {
        return {
            errors: {},
            account: ``,
            email: ``,
            password: ``,
            password_check: ``,
            isSubmitted: false
        }
    },

    computed: {},

    mounted () {
        if (this.getUsername()) {
            this.$router.push({ name: `main_home` })
        }
    },

    methods: {

        async onSubmit () {
            const form = document.querySelector(`#form_register`)
            const formData = new FormData(form)

            const postData = formDataToJson(formData)

            this.isSubmitted = true
            this.errors = {}
            try {
                await AccountApi.postAccount(postData)
                await this.$router.push({ name: `main_home` })
                await this.$store.dispatch(`addAlert`, {
                    key: `validate_account`,
                    type: `success`
                })
            } catch (e) {
                this.errors = e.response.data
            } finally {
                this.isSubmitted = false
            }

            return true
        }

    }
}
</script>
<style scoped>
span.legal {
  color: #000;
}
</style>
