<template>
  <div class="row">
    <div class="col-10 offset-md-1">
      <h2>{{ 'home_title' | trans }}</h2>
    </div>
    <div
      ref="description"
      :class="(online.length) ? `col-lg-7` : `col-lg-9`"
      class="warcraft_article description_card offset-md-1 pt-3 pl-3 pr-3 pb-3 mb-3"
    >
      <div class="row">
        <div class="col-md-12 col-lg-5 col-xl-5">
          <main-panel-texte />
        </div>
        <div
          class="col-lg-7 d-none d-md-block align-right"
        >
          <videos
            :width="854"
            :height="480"
          />
        </div>
      </div>
    </div>
    <div class="d-none d-sm-block mr-5 ml-2" />
    <div
      v-if="online.length > 0"
      class="flex-grow-1 warcraft_article mb-3 pl-3 pr-3"
      :style="`height: ${siblingHeight}px`"
    >
      <online
        :online="online"
      />
    </div>
    <div class="col-1 d-none d-lg-block" />
    <div class="col-12 linear-gradient">
      <div class="row">
        <div class="col-12 justify-content-center">
          <h3>{{ 'home_spec_title' | trans }}</h3>
        </div>
        <div
          v-for="(value, name) in descriptions"
          :key="name"
          class="col-lg-6 col-md-12"
        >
          <div class="row">
            <div class="col-4 d-none d-md-block">
              <description-icon
                :top-icon="value.t"
                :left-icon="value.l"
              />
            </div>
            <div class="col-lg-8 col-md-12">
              <h4>{{ `home_description_title_${name}`|trans }}</h4>
              <p>
                {{ `home_description_summery_${name}`|trans }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
'use strict'
import { Common } from "@/common"
import DescriptionIcon from "@/components/ui/DescriptionIcon"
import Online from "@/components/modules/home/Online"
import { ServerApi } from "@/api/ServerApi"
import Videos from "@/components/modules/home/Videos"
import MainPanelTexte from "@/components/modules/home/MainPanelTexte.vue"

export default {

    metaInfo () {
        return Common.getMetaInfo(this.$route.name)
    },
    components: { MainPanelTexte, Videos, Online, DescriptionIcon },
    data () {
        return {
            siblingHeight: 0,
            online: [],
            descriptions: {
                talent: { t: 1, l: 5 },
                profession: { t: 1, l: 6 },
                bg: { t: 2, l: 3 },
                vanilla: { t: 2, l: 5 },
                shop: { t: 2, l: 9 },
                transmog: { t: 1, l: 9 },
                ah: { t: 3, l: 9 },
                group: { t: 2, l: 1 }
            }
        }
    },
    watch: {
        '$refs.description.clientHeight' (val) {
            console.log(val)
        }
    },
    async mounted () {
        await this.$nextTick()
        await this.loadOnline()
    },
    updated () {
        this.siblingHeightUpdate()
    },
    created () {
        window.addEventListener(`resize`, this.siblingHeightUpdate)
    },
    destroyed () {
        window.removeEventListener(`resize`, this.siblingHeightUpdate)
    },

    methods: {
        async loadOnline () {
            const response = await ServerApi.getCharactersOnline()
            this.online = response.data
        },
        siblingHeightUpdate () {
            this.siblingHeight = this.$refs.description.clientHeight
        }

    }

}
</script>
<style scoped>
.linear-gradient {
    background: linear-gradient(to bottom, transparent, #211510 5rem);
}

.warcraft_article .align-right {
    text-align: right;
}

.description_card {
    height: min-content;
}
</style>
