<template>
  <div
    id="header"
    class="row"
  >
    <div class="offset-md-1  pl-0 pr-0 col-lg-10 col-sm-12 col-12">
      <nav class="navbar navbar-expand-lg navbar-light bg-ligh bg-faded pl-0">
        <a
          class="navbar-brand ml-0 mr-0"
          href="#"
        />
        <div id="logo">
          <a href="/">
            <img
              src="/images/murloc_village_logo.webp"
              alt="Murloc Village"
            >
          </a>
          <h1>World of Warcraft</h1>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          @click="openCloseMenu"
        >
          <span class="navbar-toggler-icon" />
        </button>
        <div
          :class="{ collapse }"
          class="navbar-collapse"
        >
          <!-- User Menu -->
          <ul class="navbar-nav  mr-auto menu main_menu ">
            <menu-item
              v-if="!getUser.username"
              item-title="main_home_title"
              item-root="main_home"
            />
            <menu-item
              v-if="getUser.username"
              item-title="main_download_title"
              item-root="main_download"
            />
            <menu-item
              item-title="main_forum_title"
              item-root="main_forum"
            />
            <menu-item
              v-if="getUser.username"
              item-title="main_shop_title"
              item-root="main_shop"
            />
            <menu-item
              v-if="getUser && getUser.gmlevel && getUser.gmlevel > 0"
              item-title="main_gm_title"
              item-root="main_gm"
            />

            <li
              class="nav-item discord"
              @mouseover="overDiscord=true"
              @mouseout="overDiscord=false"
            >
              <router-link
                :to="{name: `discord_frame`}"
                exact
                class="discord pt-2"
              >
                <svg-icon
                  icon-svg="discord"
                  fill="url(#gradient1)"
                  width="1.5em"
                  height="1.5em"
                >
                  <defs>
                    <linearGradient
                      v-if="!overDiscord"
                      id="gradient1"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style="stop-color:#000; stop-opacity:1"
                      />
                      <stop
                        offset="38%"
                        style="stop-color:#e2a233; stop-opacity:1"
                      />
                      <stop
                        offset="60%"
                        style="stop-color:#f0c328; stop-opacity:1"
                      />
                      <stop
                        offset="86%"
                        style="stop-color:#fff1a3; stop-opacity:1"
                      />
                      <stop
                        offset="100%"
                        style="stop-color:#ffe13e; stop-opacity:1"
                      />
                    </linearGradient>
                    <linearGradient
                      v-if="overDiscord"
                      id="gradient1"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style="stop-color:#000; stop-opacity:1"
                      />
                      <stop
                        offset="50%"
                        style="stop-color:#e2a233; stop-opacity:1"
                      />
                      <stop
                        offset="60%"
                        style="stop-color:#f0c328; stop-opacity:1"
                      />
                      <stop
                        offset="86%"
                        style="stop-color:#fff1a3; stop-opacity:1"
                      />
                      <stop
                        offset="100%"
                        style="stop-color:#ffe13e; stop-opacity:1"
                      />
                    </linearGradient>
                  </defs>
                </svg-icon>
              </router-link>
            </li>
          </ul>

          <anonymous-menu v-if="!getUser.username" />
          <logged-menu v-else />
        </div>
        <ul class="lang_menu">
          <li>
            <button
              type="button"
              @click="switchLang('en')"
            >
              <img
                src="/images/en.webp"
                alt="fr"
              >
            </button>
            <button
              type="button"
              @click="switchLang('fr')"
            >
              <img
                src="/images/fr.webp"
                alt="fr"
              >
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import loggedMenu from "./LoggedMenu.vue"
import anonymousMenu from "./AnonymousMenu.vue"
import MenuItem from "@/components/main/MenuItem.vue"
import SvgIcon from "@/components/ui/SvgIcon.vue"

export default {
    components: { SvgIcon, MenuItem, loggedMenu, anonymousMenu },
    data () {
        return {
            collapse: `collapse`,
            characters: [],
            selectedCharacter: {},
            overDiscord: false
        }
    },
    computed: {

        getUser () {
            return this.$store.getters.getUser
        },

        userMenu () {
            return this.$router.options.routes.filter(function (element) {
                return element.name && element.name.indexOf(`auth_`) !== -1
            })
        }

    },

    methods: {
        switchLang (newLang) {
            this.$emit(`switchlang`, newLang)
        },

        openCloseMenu () {
            this.collapse = (this.collapse.length) ? `` : `collapse`
        }

    }
}
</script>
<style scoped>
.discord {
  display: block !important;
}

.discord svg {
  margin: auto;
  display: block;
}
</style>
