/**
 *
 * @param {FormData} formData
 * @returns {string}
 */
export const formDataToJson = (formData) => {
    const formDataObj = {}
    formData.forEach((value, key) => {
        if (Reflect.has(formDataObj, key)) {
            if (!Array.isArray(formDataObj[key])) {
                formDataObj[key] = [formDataObj[key]]
            }
            formDataObj[key].push(value)
        } else {
            formDataObj[key] = value
        }
    })

    // Convert the regular object into a JSON string
    return JSON.stringify(formDataObj)
}
