<template>
  <div>
    <label
      v-if="errors[passwordName]"
      :for="passwordName"
      class="form-text text-danger"
    >* {{ errors[passwordName] | trans }}</label>
    <input
      :id="passwordName"
      :name="passwordName"
      :minlength="4"
      :maxlength="16"
      :placeholder="`lbl_${passwordName}` | trans"
      required
      type="password"
      class="form-control"
    >
  </div>
</template>

<script>

export default {
    props: {
        errors: {
            type: Object,
            default: () => {
            }
        },
        isSubmitted: {
            type: Boolean,
            default: false
        },
        passwordName: {
            type: String,
            default: ``
        }
    }
}
</script>
