<template>
  <div
    class="character_avatar"
    :style="`background-position: ${left}px ${top}px`"
  />
</template>

<script>
export default {
    name: `RaceIcon`,
    props: {
        gender: {
            type: Number,
            required: true
        },
        race: {
            type: Number,
            required: true
        }
    },
    computed: {
        top () {
            return this.gender * -64
        },
        left () {
            return (this.race - 1) * -64
        }
    }
}
</script>

<style>
.character_avatar {
    margin: auto;
    background-image:  url(../../../public/images/wow_race.webp);
    background-repeat: no-repeat;
    height: 64px;
    width: 64px;
    background-position: -64px 0;
}
</style>
