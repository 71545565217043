<template>
  <strong :class="`class_${character.class}`">{{ character.name }}</strong>
</template>

<script>
export default {
    name: `CharacterName`,
    props: {
        character: {
            type: Object,
            required: true
        }
    }
}
</script>
