<template>
  <div>
    <a
      class="btn warcraft_btn"
      :class="timer>0 ? `disabled`: ``"
      :href="voteSite.vote.http_link"
      :title="voteSite.vote.name"
      target="_blank"
      @click="vote(voteSite.vote.id)"
    >
      <slot>TO REPLACE</slot>
    </a>
    {{ 'next_vote' | trans }} - {{ datetimeToTime(timer) }}
  </div>
</template>

<script>
import { AccountApi } from "@/api/AccountApi"

/**
 * @typedef {Object} VoteSite
 * @property {number|undefined} interval - The ID of the interval used to update the vote timer.
 * @property {string} updated_at - The timestamp of the last time the vote was updated.
 * @property {Object} vote - An object representing the vote.
 * @property {number} vote.id - The id of the vote site.
 * @property {number} vote.seconds - The remaining time in seconds until the vote expires.
 * @property {string} vote.name - The remaining time in seconds until the vote expires.
 * @property {string} vote.http_link - The remaining time in seconds until the vote expires.
 */

export default {
    props: {
    /**
     * The vote site to display.
     * @type {VoteSite}
     */
        voteSite: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            voteSiteData: structuredClone(this.voteSite),
            timer: 0
        }
    },
    watch: {
        '$store.getters.getAccount' (val) {
            if (!val || val.length === 0) {
                return 0
            }
            this.clearVoteInterval(this.voteSiteData)
            this.addTime()
        },
        voteSite (val) {
            this.voteSiteData = structuredClone(val)
        }
    },
    mounted () {
        this.clearVoteInterval(this.voteSiteData)
        this.addTime(this.voteSiteData)
    },

    beforeDestroy () {
        clearInterval(this.siteTimer.interval)
    },
    methods: {
    /**
     * Stops the specified vote interval.
     *
     * @param {Object} intervalContainer - An object that holds information about the vote interval to be stopped.
     * @property {number|undefined} interval - The ID of the vote interval to be stopped.
     */
        clearVoteInterval (intervalContainer) {
            if (intervalContainer && intervalContainer.interval) {
                clearInterval(intervalContainer.interval)
            }
        },

        /**
     * Send the money and reload the account
     * @param {number} id
     */
        async vote (id) {
            await AccountApi.putMoney(id)
            await this.$store.dispatch(`loadAccount`)
        },

        addTime () {
            const _this = this
            const tmpTimeRemain = this.timeRemain()
            this.timer = (tmpTimeRemain > 0) ? tmpTimeRemain : 0
            clearInterval(this.voteSiteData.interval)
            this.voteSiteData.interval = setInterval(
                function () {
                    _this.timer = _this.timeRemain()
                    if (_this.timer <= 0) {
                        _this.timer = 0
                        clearInterval(_this.voteSiteData.interval)
                    }
                },
                1000)
        },

        /**
     * Calculates the remaining time in milliseconds until next re-vote.
     *
     * @returns {number} The remaining time in milliseconds.
     */
        timeRemain () {
            const seconds = this.voteSiteData.vote.seconds
            const lastTime = new Date(this.voteSiteData.updated_at)
            if (isNaN(lastTime.getTime())) {
                return 0 // Return null if voteSiteData.updated_at is not valid
            }
            lastTime.setTime(lastTime.getTime() + (seconds * 1000))
            return lastTime.getTime() - (new Date()).getTime()
        },

        /**
     * Converts a time value in milliseconds to a formatted string in the format "h:mm:ss".
     *
     * @param {number} time - The time value in milliseconds.
     * @returns {string} A string representation of the time in the format "h:mm:ss".
     */
        datetimeToTime (time) {
            const temp = {
                h: Math.trunc(time / (3600 * 1000)),
                min: Math.trunc((time / (60 * 1000)) % 60),
                s: Math.trunc((time / (1000)) % 60)
            }
            return `${temp.h}:${temp.min}:${temp.s}`
        }
    }
}
</script>

<style scoped>

</style>
