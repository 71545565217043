<template>
  <div class="col-lg-12 col-lg-offset-1 warcraft_article p-3 mb-4">
    <div class="row">
      <div class="col-md-4 d-none d-md-block">
        <image-auto-resize
          src="/images/pirate_1.webp"
          alt="Pirate"
        />
      </div>
      <div class="col-md-8 col-sm-12">
        <h3>{{ `title_game_download` | trans }}</h3>
        <p v-html="$translator(`help_download`, {})" />
        <div class="row">
          <div class="col-9">
            <table class="table table-dark center_table">
              <thead>
                <tr>
                  <th scope="col">
                    Windows
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <nc-link>
                      <a
                        href="/dl/WoW3.3.5a-Fr.zip"
                        class="btn warcraft_btn"
                      >
                        {{ 'dl_game_fr'|trans }}
                      </a>
                    </nc-link>
                  </td>
                </tr>
                <tr v-if="getUsername()">
                  <td>
                    <a
                      href="/dl/WoW3.3.5a.zip"
                      class="btn warcraft_btn"
                    >
                      {{ 'dl_game_en'|trans }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p v-html="$translator(`help_download_2`)" />
        <image-auto-resize
          src="/images/authorization_windows.webp"
          alt="Pirate"
        />
        <p v-html="$translator(`help_download_3`)" />
      </div>
    </div>
  </div>
</template>

<script>
import ImageAutoResize from "../../ui/ImageAutoResize"
import NcLink from "../../ui/NcLink.vue"

export default {
    components: { NcLink, ImageAutoResize }
}
</script>
