<template>
  <div
    ref="image_container"
  >
    <img
      ref="image"
      :src="src"
      :alt="alt"
    >
  </div>
</template>

<script>
'use strict'
const Image = require(`../../libs/Image`)
export default {
    props: {
        src: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            required: true
        },
        forceResize: {
            type: Number,
            required: false,
            default: 0
        }
    },
    watch: {
        'forceResize' () {
            this.handleResize()
        }
    },
    async mounted () {
        await this.$nextTick()
        this.handleResize()
        window.addEventListener(`resize`, this.handleResize)
    },
    updated () {
        this.handleResize()
    },
    destroyed () {
        window.removeEventListener(`resize`, this.handleResize)
    },
    methods: {
        handleResize () {
            Image.returnImageHeight(this.$refs.image, this.$refs.image_container)
        }
    }
}
</script>
