<script>

import NcLink from "@/components/ui/NcLink.vue"

export default {
    components: { NcLink },
    props: {
        itemRoot: {
            type: String,
            required: true
        },
        itemTitle: {
            type: String,
            required: true
        }
    }
}
</script>

<template>
  <li class="nav-item">
    <router-link
      :to="{name: itemRoot}"
      class="nav-link"
      exact
    >
      {{ itemTitle | trans }}
      <span class="sr-only">(current)</span>
    </router-link>
  </li>
</template>

<style scoped>
.nav-link {
  display: block !important;
  text-align: center;
  margin: auto;
}
</style>
