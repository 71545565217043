<template>
  <div class="row">
    <div class="col-12">
      <div
        id="game"
        class="row justify-content-center"
      >
        <div class="col-xl-10 col-lg-10  col-md-12">
          <h2>{{ `Le code d'honneur du royaume` | trans }}</h2>
        </div>
        <div class="col-xl-10 col-lg-10 col-md-12">
          <!-- Download -->
          <div class="col-lg-12 warcraft_article">
            <p>
              Salutations aventuriers ! En pénétrant sur notre terre sacrée, vous acceptez de suivre les
              termes et les conditions suivants énoncés ci-dessous. Veuillez lire attentivement notre code
              d'honneur avant d'entreprendre votre voyage.
            </p>

            <h5>Règles de conduite</h5>

            <p>
              Voici les règles de conduite à respecter sur notre terre sainte. Nous nous réservons le droit
              d'évaluer chaque infraction au cas par cas et les mesures prises peuvent être plus ou moins
              sévères en fonction de la gravité du manquement aux règles ci-dessous :
            </p>

            <ul>
              <li>
                Aucune discrimination ou insulte envers des individus ou des groupes en raison de leur
                race, de leur religion ou de leur origine.
              </li>
              <li>Aucune pornographie ou violence verbale ne sont tolérées.</li>
              <li>
                Aucune tricherie en utilisant des méthodes non naturelles ou en exploitant les failles
                du système.
              </li>
              <li>Aucun harcèlement, trolling ou spamming n'est autorisé.</li>
              <li>
                Aucune divulgation d'informations personnelles d'autres aventuriers n'est autorisée.
              </li>
              <li>Aucune promotion d'autres royaumes n'est autorisée.</li>
            </ul>

            <h5>Confidentialité</h5>

            <p>
              Toutes les informations personnelles que vous nous confiez, telles que votre compte et votre
              adresse e-mail, ne seront ni distribuées ni utilisées à des fins commerciales. Nous nous
              engageons à respecter votre vie privée.
            </p>

            <h5>Modifications du code d'honneur</h5>

            <p>
              Nous nous réservons le droit de modifier notre code d'honneur à tout moment sans préavis. Il
              est de votre responsabilité de consulter régulièrement notre code d'honneur pour être au
              courant des mises à jour.
            </p>

            <h5>Contactez-nous</h5>

            <p>
              Si vous avez des questions ou des préoccupations concernant notre code d'honneur, veuillez
              nous contacter sur notre forum dédié ou notre serveur Discord. Nous sommes heureux de
              discuter avec vous de tout ce qui concerne notre terre sainte.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: `Legal`
}
</script>
