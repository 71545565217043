<template>
  <div
    class="description_icon"
    :style="`background-position: ${left}px ${top}px`"
  />
</template>

<script>
export default {
    name: `DescriptionIcon`,
    props: {
        topIcon: {
            type: Number,
            required: true
        },
        leftIcon: {
            type: Number,
            required: true
        }
    },
    computed: {
        top () {
            return (this.topIcon - 1) * -128
        },
        left () {
            return (this.leftIcon - 1) * -115
        }
    }
}
</script>

<style scoped>
.description_icon {
    margin: auto;
    background-image: url(../../../public/images/mv_icons.webp);
    background-repeat: no-repeat;
    height: 128px;
    width: 115px;
    background-position: -64px 0;
}
</style>
