<template>
  <div id="account_nav">
    <span
      class="text_wow_style"
    >
      <span
        v-if="serverStatus === undefined"
      >
        Loading
      </span>
      <span v-else-if="Object.entries(serverStatus).length === 0">
        <svg-icon
          icon-svg="power"
          fill="red"
          width="1.2em"
          height="1.2em"
        />
        {{ `status_server_down` | trans }}
      </span>
      <span
        v-else-if="getUser.gmlevel > 0"
      >
        {{ `label_player_online` | trans }}: {{ serverStatus.active }}/{{ serverStatus.all }}
      </span>
      <span v-else>
        <svg-icon
          icon-svg="power"
          fill="green"
          width="1.2em"
          height="1.2em"
        />
        {{ `status_server_online` | trans }}
      </span>
    </span>
    <div
      v-if="getUser.username"
      class="float-right text_wow_style"
    >
      <router-link
        :to="{ name: 'user_settings' }"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {{ getUser.username }}
      </router-link>
      |
      <span
        v-if="showMoney && account"
        class="text_wow_style"
      >({{ account.money }}
        <img
          src="/images/wine.webp"
          alt="wine"
        >)
      </span>
    </div>
  </div>
</template>

<script>
import { ServerApi } from "@/api/ServerApi"
import SvgIcon from "@/components/ui/SvgIcon.vue"

export default {
    name: `UserInformationBar`,
    components: { SvgIcon },
    data () {
        return {
            serverStatus: undefined,
            showMoney: true
        }
    },
    computed: {

        getUser () {
            return this.$store.getters.getUser
        },

        account () {
            return this.$store.getters.getAccount
        }
    },
    watch: {
        async '$store.getters.getUser.username' (val) {
            if (val) {
                await this.$store.dispatch(`loadAccount`, {})
            }
        },

        '$store.getters.getAccount' () {
            const _this = this
            this.showMoney = false
            setTimeout(() => {
                _this.showMoney = true
            }, 20)
        }
    },
    mounted () {
        this.loadAccount()
        this.serverGetStatus()
    },
    methods: {
        /**
         * List the current user characters and set the selected one
         * @returns {boolean}
         */
        serverGetStatus: async function () {
            try {
                const response = await ServerApi.getStatus()
                this.serverStatus = response.data
            } catch (e) {
                this.serverStatus = {}
            }
        },
        openCloseMenu () {
            this.collapse = (this.collapse.length) ? `` : `collapse`
        },
        async loadAccount () {
            if (this.$store.getters.getUser.username) {
                await this.$store.dispatch(`loadAccount`, {})
            }
        }
    }
}
</script>
<style scoped>
.online {
    color: green;
}

.down {
    color: red;
}
</style>
<style>
#account_nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}
</style>
