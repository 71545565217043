/* jshint esversion: 6 */
/* jshint expr: true */

/**
 * Created by clem on 15/07/17.
 */
"use strict"
class Config {
    constructor () {
        this.duration = 500
        this.delay = 500
    }

    getApiURL () {
        if (process.env.NODE_ENV === `development`) {
            return `http://localhost:3000`
        }
    }
}

module.exports = Config
