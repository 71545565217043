<template>
  <div>
    <div />
    <div
      v-for="(topic, topicKey) in topics"
      :key="topicKey"
      class="col-xl-12 col-lg-12"
    >
      <div
        class="col-md-12 forum_topics"
        :class="{'topic_found' : topic.found, 'forum_topics_pinned' : topic.pinned}"
      >
        <router-link
          :to="{ name: 'forum_topic', params: { topic_slug: topic.slug, id: topic.id }}"
          exact
        >
          <div class="row topic">
            <div class="col-md-1 d-none d-md-block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="1.5em"
                viewBox="0 0 1000 1000"
                preserveAspectRatio="xMidYMid meet"
              >

                <g
                  transform="translate(0.000000,1000.000000) scale(0.34,-0.34)"
                  :fill="topic.is_gm ? `#00aeff` : `#fff`"
                  stroke="none"
                >
                  <path :d="icon(`murloc`)" />
                </g>
              </svg>
              <svg-icon
                v-if="topic.pinned"
                icon-svg="bell"
                fill="#f8b700"
              />
              <svg-icon
                v-if="topic.locked"
                icon-svg="lock_empty"
                fill="#f8b700"
              />
            </div>
            <div
              v-if="userIsGM()"
              class="col-md-2 d-none d-md-block"
            >
              <action-buttons
                field="pinned"
                :topic="topic"
                field-disabled-icon="arrow_up"
                field-enabled-icon="arrow_down"
              />
              <action-buttons
                field="hidden"
                :topic="topic"
                field-disabled-icon="x_circle"
                field-enabled-icon="trash"
              />
              <action-buttons
                field="locked"
                :topic="topic"
                field-disabled-icon="unlock_empty"
                field-enabled-icon="lock_empty"
              />
            </div>
            <div :class="`col-md-${userIsGM()?4:6} col-8`">
              {{ topic.title }}
            </div>
            <div class="col-md-1 d-none d-md-block">
              <span>{{ topic.count_post }}</span>
            </div>
            <div class="col-md-2 col-3">
              <span>{{ topic.author.name }}</span>
            </div>
            <div class="col-md-2 d-none d-md-block">
              <span>
                {{ (new DateTime(topic.updated_at)).returnDiff().time }}
                {{ (new DateTime(topic.updated_at)).returnDiff().dictionary_key | trans }}
              </span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButtons from "./ActionButtons.vue"
import SvgIcon from "@/components/ui/SvgIcon.vue"

const svgImage = require(`../../../svg`)
const { DateTime } = require(`character-services`)

export default {
    name: `ListTopics`,
    components: { SvgIcon, ActionButtons },

    props: {
        topics: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            searchValue: ``,
            onButtonKey: 0,
            onTopicKey: 0,
            DateTime
        }
    },
    computed: {
        getUser () {
            return this.$store.getters.getUser
        }
    },
    methods: {
        icon (iconName) {
            return svgImage[iconName]
        },
        userIsGM () {
            return (this.getUser && this.getUser.gmlevel && this.getUser.gmlevel > 0)
        }
    }
}
</script>
