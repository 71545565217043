<template>
  <div class="row justify-content-center">
    <div class="col-6">
      <div class="row justify-content-center warcraft_article">
        <form
          id="form_change_forgotten_password"
          method="post"
          @submit.prevent="onSubmit"
        >
          <div
            :id="$route.name+'_form'"
            class="centered warcraft_form auth_form"
          >
            <password-comp
              :errors="errors"
              :is-submitted="isSubmitted"
              password-name="password"
            />
            <password-comp
              :errors="errors"
              :is-submitted="isSubmitted"
              password-name="password_check"
            />
            <button
              id="btn_login"
              :disabled="isSubmitted"
              class="btn warcraft_btn"
              name="doLogin"
              type="submit"
              value="Login"
            >
              {{ 'btn_submit' | trans }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import passwordComp from './PasswordComp.vue'
import { AccountApi } from "@/api/AccountApi"
import { formDataToJson } from "@/libs/formDataUtils"

export default {
    components: { passwordComp },
    data () {
        return {
            errors: {},
            isSubmitted: false
        }
    },

    methods: {

        async onSubmit () {
            const form = document.querySelector(`#form_change_forgotten_password`)
            const formData = new FormData(form)
            const postData = formDataToJson(formData)
            try {
                await AccountApi.putAccountLostPassReplace(this.$route.params.key_pass, postData)
                await this.$router.push({ name: `main_home` })
                await this.$store.dispatch(`addAlert`, {
                    key: `msg_init_password_done`,
                    type: `success`
                })
            } catch (error) {
                this.errors = error.response.data
                await this.$store.dispatch(`addAlert`, {
                    key: `msg_no_reset_key`,
                    type: `danger`
                })
            } finally {
                this.isSubmitted = false
            }
        }

    }
}

</script>
