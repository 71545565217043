/* jshint esversion: 6 */
/* jshint expr: true */
/**
 * Created by clem on 4/9/17.
 */
'use strict'

export default {

    /**
     * Structure of the message should be  {id: int, mainMessage: string, message: string, type: string[enum]}
     *
     * @param state
     * @param message
     */
    addAlert (state, message) {
        let currentMessage = false
        state.alerts.messages.forEach(function (element) {
            if (element.key === message.key) {
                currentMessage = element
                element.display = `alert_show`
            }
        })

        // Message not found then added
        if (!currentMessage) {
            message.show = false
            message.display = `alert_show`
            state.alerts.messages.push(message)
            currentMessage = message
        }
    },

    /**
     * Remove alert by key with fadeout effect
     * @param state
     * @param key
     */
    delAlert (state, key) {
        let currentMessage = false
        state.alerts.messages.forEach(function (element) {
            if (element.key === key) {
                currentMessage = element
                element.display = `alert_hide`
            }
        })

        if (currentMessage) {
            setTimeout(function () {
                state.alerts.messages = state.alerts.messages.filter(function (element) {
                    return (element.key !== key)
                })
            }, 600)
        }
    },

    /**
     * Remove all the alerts
     * @param state
     */
    cleanAlerts (state) {
        state.alerts.messages.forEach(function (element) {
            if (element.show) {
                element.display = `alert_hide`
            }
        })
    },

    /**
     *
     * @param state
     * @param user
     */
    setUser (state, user) {
        state.user = user
    },

    setCurrentCharacter (state, character) {
        state.currentCharacter = character
    },

    setCharacters (state, characters) {
        state.characters = characters
    },

    loadAccount (state, account) {
        state.account = account
    },
    setViewerScriptsLoaded (state, viewerScriptsLoaded) {
        state.viewerScriptsLoaded = viewerScriptsLoaded
    }
}
