<template>
  <div class="row justify-content-center">
    <div class="col-6">
      <div class="row justify-content-center warcraft_article">
        <form
          id="form_change_password"
          method="post"
          @submit.prevent="onSubmit"
        >
          <div class="centered warcraft_form auth_form">
            <strong>{{ `title_change_password` | trans }}</strong>
            <password-comp
              :errors="errors"
              :is-submitted="isSubmitted"
              password-name="old_password"
            />
            <password-comp
              :errors="errors"
              :is-submitted="isSubmitted"
              password-name="password"
            />
            <password-comp
              :errors="errors"
              :is-submitted="isSubmitted"
              password-name="password_check"
            />
          </div>

          <button
            id="btn_login"
            :disabled="isSubmitted"
            class="btn warcraft_btn"
            name="doLogin"
            type="submit"
            value="Login"
          >
            {{ 'btn_submit' | trans }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { AccountApi } from "@/api/AccountApi"
import passwordComp from './PasswordComp.vue'
import { formDataToJson } from "@/libs/formDataUtils"

export default {
    components: { passwordComp },
    data () {
        return {
            errors: {},
            isSubmitted: false
        }
    },

    methods: {

        async onSubmit () {
            const form = document.querySelector(`#form_change_password`)
            const formData = new FormData(form)
            const postData = formDataToJson(formData)
            try {
                await AccountApi.putPassword(postData)
                await this.$store.dispatch(`addAlert`, {
                    key: `success_password_change`,
                    type: `success`
                })
            } catch (e) {
                await this.$store.dispatch(`addAlert`, {
                    key: `danger_password_change`,
                    type: `danger`
                })
            }
        }
    }
}
</script>
