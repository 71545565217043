<template>
  <div>
    <label
      v-if="errors.val_sum"
      for="val_sum"
      class="form-text text-danger"
    >* {{ errors.val_sum | trans }}</label>
    <div class="row sum">
      <div class="sum_of col-1">
        {{ val1 }}
        <input
          :value="val1"
          type="hidden"
          name="val1"
        >
      </div>
      <div class="sum_of col-1">
        +
      </div>
      <div class="sum_of col-1">
        {{ val2 }}
        <input
          :value="val2"
          type="hidden"
          name="val2"
        >
      </div>
      <div class="sum_of col-1">
        +
      </div>
      <div class="sum_of col-1">
        {{ val3 }}
        <input
          :value="val3"
          type="hidden"
          name="val3"
        >
      </div>
      <div class="sum_of col-1">
        =
      </div>
      <div class="col-5">
        <input
          id="val_sum"
          v-model="val_sum"
          :disabled="isSubmitted"
          maxlength=""
          name="val_sum"
          required
          type="number"
          class="form-control"
        >
      </div>
      <input
        type="hidden"
        name="check"
        :value="check"
      >
    </div>
  </div>
</template>
<script>
'use strict'

import { AccountApi } from "@/api/AccountApi"

export default {
    props: {
        errors: {
            type: Object,
            default: () => {
            }
        },
        isSubmitted: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            val1: undefined,
            val2: undefined,
            val3: undefined,
            check: undefined,
            val_sum: 0
        }
    },
    async mounted () {
        const captcha = await AccountApi.getCaptcha()
        this.val1 = captcha.data.n1
        this.val2 = captcha.data.n2
        this.val3 = captcha.data.n3
        this.check = captcha.data.check
    }
}
</script>
