/* jshint esversion: 9 */
/* jshint expr: true */

"use strict"
import { myAxios } from '@/custom_axios'

export class ServerApi {
    /**
     * Return current account
     * @returns {Promise<*>}
     */
    static async getStatus () {
        return myAxios.get(`/api/server/status`)
    }

    static async getCharactersOnline () {
        return myAxios.get(`/api/server/online`)
    }
}
