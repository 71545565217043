/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { myAxios } from '@/custom_axios'

export class CharactersApi {
    static async putAccountCharacters (character) {
        return myAxios.put(`/api/secure/characters`, character)
    }

    /**
     *
     * @param {int}guid
     * @return {Promise<AxiosResponse<any>>}
     */
    static async getCharacter (guid) {
        return myAxios.get(`/api/characters/${guid}`)
    }

    /**
     *
     * @param {int}guid
     * @return {Promise<AxiosResponse<any>>}
     */
    static async getCharacterEquipment (guid) {
        return myAxios.get(`/api/characters/${guid}/items`)
    }
}
