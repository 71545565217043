<template>
  <div>
    <label
      class="text-lg"
      for="post_message"
    >{{ 'forum_message_label' | trans }}</label>
    <label
      v-if="errors.post_message"
      class="form-text text-danger"
    >{{ errors.post_message | trans }}</label>
    <div
      id="post_message"
      contenteditable="true"
      class="form-control editable_div"
      @input="updateMessage"
      @paste="onPaste"
    />
    <input
      :value="message"
      type="hidden"
      name="post_message"
    >
  </div>
</template>
<script>
export default {

    props: {
        errors: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            message: ``
        }
    },
    computed: {
        getUser () {
            return this.$store.getters.getUser
        }
    },
    methods: {
        updateMessage: function (event) {
            this.message = event.target.innerText
            this.$emit(`update-message`, this.message)
        },
        onPaste: function (event) {
            event.preventDefault()
            const text = (event.clipboardData || window.clipboardData).getData(`text`)
            document.execCommand(`insertText`, false, text)
        }
    }
}
</script>
