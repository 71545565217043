<template>
  <span>
    <abbr
      :title="help|trans"
    >
      <button
        class="btn"
        @click.prevent="change"
      >
        <svg-icon
          :icon-svg="icon"
          fill="#00ccff"
        />
      </button>
    </abbr>
  </span>
</template>

<script>

import { TopicsApi } from "@/api/TopicsApi"
import SvgIcon from "@/components/ui/SvgIcon.vue"

const svgImage = require(`../../../svg`)

export default {
    name: `ActionButtons`,
    components: { SvgIcon },
    props: {
        topic: {
            type: Object,
            required: true
        },
        field: {
            type: String,
            required: true
        },
        fieldEnabledIcon: {
            type: String,
            required: true
        },
        fieldDisabledIcon: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            svgImage
        }
    },
    computed: {
        icon () {
            return this.topic[this.field] ? this.fieldEnabledIcon : this.fieldDisabledIcon
        },
        getUser () {
            return this.$store.getters.getUser
        },
        help () {
            return `forum_topic_button_${this.field}_${this.topic[this.field]}`
        }
    },

    methods: {
        async change () {
            const patch = {}
            this.topic[this.field] = !this.topic[this.field]
            patch[this.field] = this.topic[this.field]
            try {
                await TopicsApi.patchTopic(this.topic.id, patch)
            } catch (e) {
                this.topic[this.field] = !this.topic[this.field]
            }
        }

    }
}
</script>
