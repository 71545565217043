<template>
  <div
    ref="video_container"
    class="container"
  >
    <iframe
      id="ytplayer"
      ref="video"
      type="text/html"
      :width="width"
      :height="height"
      :src="url"
      frameborder="0"
      class="video"
    />
  </div>
</template>

<script>
'use strict'
export default {
    props: {
        width: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        url: {
            type: String,
            required: true
        }
    }
}
</script>
<style scoped>
  .youtube {
    border: 1px solid red;
  }
  .container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
