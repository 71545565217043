'use strict'

import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const cookieStore = require(`./cookie_store`)

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        alerts: {
            messages: []
        },

        user: {
            username: cookieStore.getCookie(`username`),
            gmlevel: cookieStore.getCookie(`gmlevel`),
            access_token: cookieStore.getCookie(`access_token`),
            mirror_token: cookieStore.getCookie(`mirror_token`)
        },

        account: null,
        currentCharacter: null,
        characters: [],
        viewerScriptsLoaded: false

    },

    getters,

    mutations,

    actions
})
