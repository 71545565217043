<template>
  <div class="col-lg-10 warcraft_article p-3 mb-4">
    <div
      class="row"
    >
      <div class="col-md-8 col-sm-12">
        <h3>{{ `title_vote` | trans }}</h3>
        {{ `description_vote` | trans }}
        {{ serviceConf.voteReward }}
        <img
          src="/images/wine.webp"
          alt="wine"
        ><br>
        <nc-link>
          <div
            v-for="(voteSite, index) in accountVotes"
            :key="index"
          >
            <vote-btn
              v-if="voteSite.vote"
              :vote-site="voteSite"
            >
              {{ 'btn_vote' | trans }} {{ index + 1 }}
            </vote-btn>
          </div>
        </nc-link>
        <div class="mt-4">
          <h5>{{ `title_shopping` | trans }}</h5>
          <ul>
            <li>{{ `description_shopping_1` | trans }}</li>
            <li>{{ `description_shopping_2` | trans }}</li>
            <li>{{ `description_shopping_3` | trans }}</li>
          </ul>
        </div>
      </div>
      <div class="col-md-4 d-none d-md-block">
        <div class="text-center">
          <img
            class="img-fluid"
            src="/images/alliance_treasure_chest.webp"
            alt="Treasure"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NcLink from "../../ui/NcLink.vue"
import { VotesApi } from "@/api/VotesApi"
import VoteBtn from "@/components/modules/services/ui/VoteBtn.vue"
import { serviceConf } from "character-services"

export default {
    components: { VoteBtn, NcLink },
    data () {
        return {
            voteList: [],
            serviceConf
        }
    },
    computed: {
    /**
     *
     * @return {VoteSite[]|[]}
     */
        accountVotes () {
            let accountVotes = []
            try {
                accountVotes = this.$store.getters.getAccount.account_votes
            } catch (e) {
            }
            return this.voteList.map(
                vl => accountVotes.find(av => av.vote.id === vl.vote.id) || vl
            )
        }

    },

    async beforeMount () {
        const resp = await VotesApi.getVotes()
        this.voteList = resp.data.map(elem => ({
            vote: elem
        }))
    }
}
</script>

<style scoped>
a.disabled {
  pointer-events: none;
  cursor: default;
}

.btn.disabled, a.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.65 !important;
}
</style>
