<template>
  <div class="online_panel pb-4">
    <div class="d-flex justify-content-center">
      <h5>
        En ligne
      </h5>
    </div>
    <div class="list_characters">
      <div
        v-for="(character, key) in online"
        :key="key"
        class="d-flex justify-content pb-1 mb-1 character_separator"
      >
        <div class="d-flex">
          <race-icon
            :gender="character.gender"
            :race="character.race"
          />
        </div>
        <div class="d-flex justify-content-start">
          <div class="col-lg-12">
            <strong class="topic_message_author">
              <span>{{ character.name }}</span>
            </strong>
            <br>
            <span :class="'class_'+character.class">{{ ('class_' + character.class) | trans }} {{
              'label_level' | trans
            }} {{ character.level }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RaceIcon from "@/components/ui/RaceIcon"

export default {
    name: `Online`,
    components: { RaceIcon },
    props: {
        online: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped>
.list_characters {
  height: 80%;
  overflow-y: scroll;
}

.character_separator {
  border-bottom: 1px solid #544843;
}

.online_panel {
  height: 100%;
}
</style>
