/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { myAxios } from '@/custom_axios'

export class MailApi {
    static async getEmails () {
        return myAxios.get(`/api/game-master/mail/`)
    }

    static async sendTest (emailId) {
        return myAxios.post(`/api/game-master/mail/test/${emailId}`)
    }

    static async sendEmails (emailId) {
        return myAxios.post(`/api/game-master/mail/send/${emailId}`)
    }
}
