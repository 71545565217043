class WorldEvents {
    /**
     * Return true if we are in Love is in the air advertising period.
     * @returns {boolean}
     */
    get loveIsInTheAir () {
        const now = new Date()
        return new Date(now.getFullYear(), 1, 5) < now && now < new Date(now.getFullYear(), 1, 20)
    }
}

export const worldEvents = new WorldEvents()
