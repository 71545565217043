<template>
  <div id="app_footer">
    <div v-if="$route.name === 'auth_login'" />
  </div>
</template>
<script>
export default {
    data () {
        return {

        }
    }
}
</script>
<style>
    #app_footer{
        position: absolute;
        bottom: 0;
    }
</style>
