<template>
  <div>
    <you-tube-auto-resize
      v-if="worldEvents.loveIsInTheAir"
      :width="width"
      :height="height"
      url="https://www.youtube.com/embed/lLZiPM0PtqA?loop=1&autoplay=1&mute=1&playlist=lLZiPM0PtqA"
    />
    <you-tube-auto-resize
      v-else
      :width="width"
      :height="height"
      url="https://www.youtube.com/embed/F9XO48MLC4w?loop=1&autoplay=1&mute=1&playlist=F9XO48MLC4w"
    />
  </div>
</template>

<script>
import YouTubeAutoResize from "@/components/ui/YouTubeAutoResize"
import { worldEvents } from "@/worldEvents"

export default {
    name: `Videos`,
    components: { YouTubeAutoResize },
    props: {
        width: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            worldEvents
        }
    }
}
</script>

<style scoped>

</style>
