<template>
  <div class="row justify-content-md-center">
    <div
      id="alerts"
      class="col-lg-4 centered"
    >
      <div
        v-for="(alert, keyAlert) in alerts.messages"
        :id="'alert_id_'+alert.key"
        :key="keyAlert"
        :class="'alert alert-'+alert.type+' alert-dismissable '+alert.display"
        role="alert"
      >
        <a
          class="close"
          data-dismiss="alert"
          aria-label="close"
          @click="onDelete(alert.key)"
        >&times;</a>
        {{ alert.key | trans }}
      </div>
    </div>
  </div>
</template>
<script>
'use strict'
export default {
    data () {
        return {
            currentRoute: this.$route.name
        }
    },
    computed: {
        alerts () {
            return this.$store.getters.alertsMessages
        }
    },
    methods: {
        async onDelete (messageKey) {
            await this.$store.dispatch(`delAlert`, messageKey)
        }
    }

}
</script>
