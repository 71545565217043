<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <slot />
    </defs>
    <path
      v-for="dPath in svgImage[iconSvg]"
      :key="dPath"
      :d="dPath"
      :fill="fill"
    />
  </svg>
</template>

<script>
const svgImage = require(`../../svg`)

export default {
    name: `SvgIcon`,
    props: {
        iconSvg: {
            type: String,
            required: true
        },
        width: {
            type: String,
            default: `1em`
        },
        height: {
            type: String,
            default: `1em`
        },
        fill: {
            type: String,
            default: `#000000`
        }
    },
    data () {
        return {
            svgImage
        }
    }
}
</script>
