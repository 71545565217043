<template>
  <!-- Authentication menu -->
  <ul class="nav navbar-nav menu">
    <li
      v-for="(route, routeKey) in userMenu"
      :key="routeKey"
      class="nav-item"
    >
      <router-link
        :to="{name: route.name}"
        class="nav-link"
        exact
      >
        {{ route.name + '_title' | trans }}
        <span class="sr-only">(current)</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
    name: `AnonymousMenu`,
    computed: {
        userMenu () {
            return this.$router.options.routes.filter(element => element.name && element.name.indexOf(`auth_`) !== -1)
        }

    }
}
</script>
