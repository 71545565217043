/* jshint esversion: 6 */
/* jshint expr: true */

/**
 * Created by clem on 4/9/17.
 */
'use strict'
import { myAxios } from '@/custom_axios'
import { AccountApi } from "@/api/AccountApi"

const cookieStore = require(`./cookie_store`)

export default {
    addAlert (context, message) {
        context.commit(`addAlert`, message)
    },

    delAlert (context, key) {
        context.commit(`delAlert`, key)
    },

    cleanAlerts (context) {
        context.commit(`cleanAlerts`)
    },

    /**
     *
     * @param context
     * @param {{}} user
     */
    setUser (context, user) {
        for (const key in user) {
            if (user[key]) {
                cookieStore.setCookie(key, user[key])
            } else {
                cookieStore.delCookie(key)
            }
        }
        context.commit(`setUser`, user)
    },

    setCurrentCharacter (context, character) {
        context.commit(`setCurrentCharacter`, character)
    },

    /**
     *
     * @param context
     */
    async loadAccount (context) {
        try {
            const res = await AccountApi.getAccount()
            await context.commit(`loadAccount`, res.data)
        } catch (e) {
            await context.commit(`loadAccount`, null)
        }
    },

    /**
     *
     * @param context
     */
    async loadCharacters (context) {
        try {
            const response = await myAxios.get(`/api/secure/characters`)
            const characters = response.data
            const selectedCharacter = characters.find(element => element.selected)
            context.commit(`setCharacters`, characters)
            context.commit(`setCurrentCharacter`, selectedCharacter)
        } catch (e) {
            context.commit(`setCurrentCharacter`, null)
            context.commit(`setCharacters`, [])
        }
    },

    async resetCharacters (context) {
        context.commit(`setCurrentCharacter`, null)
        context.commit(`setCharacters`, [])
    },

    async setViewerScriptsLoaded (context, viewerScriptsLoaded) {
        context.commit(`setViewerScriptsLoaded`, viewerScriptsLoaded)
    }
}
