<template>
  <span>

    <router-link
      v-if="!getUsername() && !noButton"
      :to="{name: 'auth_login'}"
      class="btn warcraft_btn"
      rel="nofollow"
    >
      {{ 'auth_login_title'|trans }}
    </router-link>
    <slot v-else-if="getUsername()">
      TODO connected action
    </slot>
  </span>
</template>

<script>
/**
 * If user is connected the content of ths slot is shown else a connection button
 */
export default {
    name: `NcLink`,
    props: {
        noButton: {
            type: Boolean,
            default: false
        }
    }
}
</script>
