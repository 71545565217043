<template>
  <div class="col-lg-10 warcraft_article p-3 mb-4">
    <div class="row">
      <div class="col-md-8 col-sm-12">
        <h3>{{ `title_rename` | trans }}</h3>
        {{ `description_rename` | trans }}
        <character-name :character="character" />
        {{ `description_rename_2` | trans }} {{ prices.rename }}
        <img
          src="/images/wine.webp"
          alt="wine"
        >
        <br><br>
        <div>
          <nc-link :no-button="true">
            <form
              method="post"
              @submit.prevent="onSubmit"
            >
              <button
                :disabled="isSubmitted"
                class="btn warcraft_btn"
                type="submit"
              >
                {{ 'buy_rename'|trans }}
              </button>
            </form>
          </nc-link>
        </div>
      </div>
      <div
        ref="image_container"
        class="col-md-4 d-none d-md-block"
      >
        <img
          class="mx-auto d-block img-fluid"
          src="/images/rename.webp"
          alt="Rename"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ServicesApi } from "@/api/ServicesApi"
import NcLink from "../../ui/NcLink.vue"
import CharacterName from "@/components/ui/CharacterName"
import { serviceConf } from "character-services"

export default {
    components: { CharacterName, NcLink },
    data () {
        return {
            isSubmitted: false,
            rename: undefined,
            prices: serviceConf
        }
    },
    computed: {
        getUser () {
            return this.$store.getters.getUser
        },

        account () {
            return this.$store.getters.getAccount
        },

        character () {
            // return this.$store.getters.getCurrentCharacter
            return this.$store.getters.getServiceCharacter
        }
    },
    methods: {
        async onSubmit () {
            try {
                this.isSubmitted = true
                await ServicesApi.putRename()
                await this.$store.dispatch(`addAlert`, { key: `success_rename`, type: `success` })
                await this.$store.dispatch(`loadAccount`)
            } catch (error) {
                this.isSubmitted = false
                let errorMessage
                if (error.response.status === 500) {
                    errorMessage = `error_unexpected`
                } else if (error.response.status === 402) {
                    errorMessage = `error_money`
                }
                await this.$store.dispatch(`addAlert`, {
                    key: errorMessage,
                    type: `danger`
                })
            } finally {
                this.isSubmitted = false
                await this.$store.dispatch(`delAlert`, `info_rename`)
            }
            return false
        }
    }
}
</script>
