<template>
  <div class="row">
    <div class="col-12">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-6  col-md-12">
          <h2>{{ `main_forum_general` | trans }}</h2>
          <small>{{ `main_forum_description` | trans }}</small>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-12 form-group topic_actions warcraft_form">
          <label>
            <input
              v-model="searchValue"
              class="form-control topic_search"
              type="text"
              placeholder="Search"
            >
          </label>
          <nc-link>
            <router-link
              :to="{name: 'forum_topic_new'}"
              class="btn warcraft_btn"
            >
              {{ 'link_new_topic'|trans }}
            </router-link>
          </nc-link>
        </div>
        <div
          v-if="topicsData.length"
          class="col-xl-10 col-lg-10 pl-0 pr-0"
        >
          <list-topics
            v-if="pinned"
            :topics="pinned"
          />
          <br>
          <list-topics
            v-if="unpinned"
            :topics="unpinned"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { TopicsApi } from "@/api/TopicsApi"
import NcLink from "../../ui/NcLink.vue"
import { Common } from "@/common"
import ListTopics from "@/components/modules/forum/ListTopics.vue"

export default {
    metaInfo () {
        return Common.getMetaInfo(this.$route.name)
    },
    components: { ListTopics, NcLink },
    data () {
        return {
            topicsData: [],
            searchValue: ``
        }
    },

    computed: {

        getUser () {
            return this.$store.getters.getUser
        },
        found () {
            const _this = this
            return this.topicsData.filter((element) => {
                const inTitle = ((element.title)
                    .toLowerCase()
                    .search(_this.getSearchValue.toLowerCase()) !== -1)
                const inAuthor = ((element.author.name)
                    .toLowerCase()
                    .search(_this.getSearchValue.toLowerCase()) !== -1)
                return inTitle || inAuthor
            })
        },
        pinned () {
            return this.found.filter(e => e.pinned)
        },
        unpinned () {
            return this.found.filter(e => !e.pinned)
        },

        getSearchValue () {
            return this.searchValue
        }

    },

    async mounted () {
        await this.loadTopics()
    },

    methods: {
        async loadTopics () {
            const response = await TopicsApi.getTopics()
            this.topicsData = response.data
        }
    }
}
</script>
