/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { myAxios } from '@/custom_axios'

export class ServicesApi {
    static async getItems () {
        return myAxios.get(`/api/items`)
    }

    static async putMount () {
        return myAxios.put(`/api/secure/services/mount`)
    }

    static async putPet () {
        return myAxios.put(`/api/secure/services/pet`)
    }

    static async putRevive () {
        return myAxios.put(`/api/secure/services/revive`)
    }

    static async putRename () {
        return myAxios.put(`/api/secure/services/rename`)
    }

    static async putLevel () {
        return myAxios.put(`/api/secure/services/level`)
    }

    static async putCommand (command) {
        return myAxios.put(`/api/secure/services/${command}`)
    }
}
